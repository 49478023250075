import React, { createContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import PlatformContext from 'context/platform/PlatformContext'
import { createPlatform } from './helpers/platforms/PlatformFactory'
import Hebrew from './lang/he.json'
import App from './App'
import AuthRoot from './components/auth/AuthRoot'
import FetchProvider from './context/fetch/FetchProvider'

export const StrapiURLContext = createContext()

const platform = createPlatform()

export default function AppContainer() {
    const [strapiURL, setStrapiURL] = useState(process.env.REACT_APP_STRAPIURL)

    const [version, setVersion] = useState()

    useEffect(() => {
        async function updateVersion() {
            const appVersion = await platform.getAppVersion()
            setVersion(appVersion)
        }

        updateVersion()
    }, [])


    return (
        <IntlProvider locale="he" defaultLocale="he" messages={Hebrew}>
            <PlatformContext.Provider value={platform}>
                <StrapiURLContext.Provider value={strapiURL}>
                    <AuthRoot>
                        {
                            version != null
                                ? (
                                    <FetchProvider version={version}>
                                        <App
                                            setStrapiURL={setStrapiURL}
                                            platform={platform}
                                        />
                                    </FetchProvider>
                                )
                                : null
                        }
                    </AuthRoot>
                </StrapiURLContext.Provider>
            </PlatformContext.Provider>
        </IntlProvider>
    )
}
