/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// import {
//   Icon,
// } from '@mui/material'

// Custom styles for MDButton
import MDButtonRoot from 'components/MDButton/MDButtonRoot'

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from 'context/material-ui'

const MDButton = forwardRef(
    (
        {
            color = 'white',
            variant = 'contained',
            size = 'medium',
            circular = false,
            iconOnly = false,
            // icon,
            children,
            ...rest
        },
        ref,
    ) => {
        const [controller] = useMaterialUIController()
        const { darkMode } = controller

        return (
            <MDButtonRoot
                {...rest}
                ref={ref}
                color="primary"
                variant={variant === 'gradient' ? 'contained' : variant}
                size={size}
                ownerState={{ color, variant, size, circular, iconOnly, darkMode }}
            >
                {/* {icon && <Icon>{icon}</Icon>} */}
                {children}
            </MDButtonRoot>
        )
    },
)

// Typechecking props for the MDButton
MDButton.propTypes = {
    size: PropTypes.oneOf(['small', 'medium', 'large']),
    variant: PropTypes.oneOf(['text', 'contained', 'outlined', 'gradient']),
    color: PropTypes.oneOf([
        'white',
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
    ]),
    circular: PropTypes.bool,
    iconOnly: PropTypes.bool,
    children: PropTypes.node.isRequired,
}

export default MDButton
