import React from 'react'
import MDBox from '../MDBox'
import MDTypography from '../MDTypography'
import StatusBadge from '../StatusBadge'

export default function PageTitle ({ title, status }) {
    return (
        <MDBox
            mt={0}
            mb={0}
            sx={{
                display: 'flex',
                alignItems: 'center',
            }}
        >
            {title == null
                ? null
                : <MDTypography variant="h1">{title}</MDTypography>
            }
            {status && (
                <StatusBadge
                    status={status}
                    sx={{
                        marginInlineStart: 3,
                    }}
                />
            )}
        </MDBox>
    )
}
