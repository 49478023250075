const companyTypes = {
    Public_Company: 'חברה ציבורית',
    Private_Company: 'חברה פרטית',
    Authorized_Contractor: 'עוסק מורשה',
    Exempt_Contractor: 'עוסק פטור',
    Non_Profit_Organization: 'ארגון ללא מטרות רווח',
}

const reportingPeriodType = {
    OnceInAMonth: 'דיווח אחת לחודש',
    OnceInTwoMonths: 'דיווח אחת לחודשיים',
}

export {
    companyTypes,
    reportingPeriodType,
}
