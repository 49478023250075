// import { isElectron } from 'is-electron'
import { ElectronPlatform } from './ElectronPlatform'
import { BrowserPlatform } from './BrowserPlatform'

// This function was copied from https://github.com/cheton/is-electron/blob/ba0a4eca984e0f8dab06e071e24b57355f14814e/index.js
function isElectron() {
    // Renderer process
    if (typeof window !== 'undefined' && typeof window.process === 'object' && window.process.type === 'renderer') {
        return true
    }

    // Main process
    if (typeof process !== 'undefined' && typeof process.versions === 'object' && !!process.versions.electron) {
        return true
    }

    // Detect the user agent when the `nodeIntegration` option is set to false
    if (typeof navigator === 'object' && typeof navigator.userAgent === 'string' && navigator.userAgent.indexOf('Electron') >= 0) {
        return true
    }

    return false
}

export function createPlatform() {
    // const isElectron = window && window.process && window.process.type
    const isElectronApp = isElectron()
    return isElectronApp ? new ElectronPlatform() : new BrowserPlatform()
}
