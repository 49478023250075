import { useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import { useNotificationContext } from '../../units/notifications/NotificationContext'
import FetchContext from '../../context/fetch/FetchContext'
import EntryPageExistingDocuments from '../entry-page-existing-documents/entryPageExistingDocuments'
import Loader from '../Loader'
import useQueryLibrary from '../../hooks/useQueryLibrary'

export default function EntryPage(props) {
    const [busy, setBusy] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const { companyId, entryId } = useParams()

    const navigate = useNavigate()

    const queryClient = useQueryClient()
    const queryLibrary = useQueryLibrary()

    const { addNotification } = useNotificationContext()

    const fetcher = useContext(FetchContext)

    const createDocumentMutation = useMutation({
        mutationFn: (data) => {
            console.log('mutating')
            setBusy(true)

            return fetcher.authenticatedFetch({
                method: 'post',
                path: `/documents`,
                data: { data },
                headers: {
                    'Content-Type': 'application/json',
                },
            })
        },
        onSuccess: (data) => {
            console.log(data)
            const documentId = data.id

            queryClient.invalidateQueries(
                queryLibrary.oneDocumentQuery(documentId),
            )
            queryClient.invalidateQueries(
                queryLibrary.oneEntryQuery(entryId),
            )
            queryClient.invalidateQueries(
                queryLibrary.oneDocumentForDocumentPageQuery(documentId),
            )
            queryClient.invalidateQueries(
                queryLibrary.oneEntryForEntryPageQuery(documentId),
            )

            navigate(`/companies/${companyId}/entries/${entryId}/documents/${documentId}`)
        },
        onError: (error) => {
            addNotification({
                type: 'error',
                content: error?.response?.data?.error?.message,
            })
        },
        onSettled: () => {
            setIsLoading(false)
            setBusy(false)
        },
    })

    const createDocument = async (type, kind, editor) => {
        setIsLoading(true)

        // return (new Promise(async (res, rej) => {

        const data = {
            type,
            kind,
            editor,
            entry: [
                entryId,
            ],
        }

        createDocumentMutation.mutate(data)

        // await fetch({
        //   method: 'post',
        //   path: `/documents`,
        //   data: { data },
        //   headers: {
        //     'Content-Type': 'application/json',
        //   },
        //   responseHandler: (response) => {
        //     console.log(response.data)
        //     const documentID = response.data.data.id
        //     RedirectToURL(`/companies/${companyId}/entries/${entryId}/Document/${documentID}`, props)
        //   },
        // })

        // setIsLoading(false)

        // }))
    }

    // const commands = screens.map((screenName, index) => ({
    //   key: index,
    //   label: screenName,
    //   color: 'secondary',
    //   className: `entry-page_set-screen-button ${screen === screenName ? 'current' : ''}`,
    //   onClick: () => setScreen(screenName),
    // }))

    const existingDocuments =
        <EntryPageExistingDocuments
            match={props.match}
            // token={props.token}
            createDocument={createDocument}
        />

    //   // <Page className="entry-page" commands={commands}>
    //     {/* <div className="entry-page_set-screen-buttons">
    //       {screens.map((screenName, index) => (
    //         <MDButton
    //           key={index}
    //           onClick={() => setScreen(screenName)}
    //           className={`entry-page_set-screen-button ${screen === screenName ? 'current' : ''}`}
    //         >{screenName}</MDButton>
    //       ))}
    //     </div> */}
    //     <EntryPageExistingDocuments match={props.match} token={props.token} createDocument={createDocument} />
    //   // </Page>
    // )

    return isLoading ? <Loader minHeight="50vh" /> : existingDocuments
}
