export async function activateScanner(fetcher, trigger) {
    try {
        const config = {}
        if (trigger === true) {
            config.baseUrl = `http://localhost:51111/readmag`
        }
        else if (typeof trigger === 'string') {
            config.path = trigger
        }
        const scannedData = await fetcher.fetch({
            method: 'get',
            // baseUrl: url || `http://localhost:51111/readmag`,
            ...config,
            responseHandler: (response) => response.data,
        })

        // File uploaded successfully!
        return scannedData
    }
    catch (error) {
        // File upload failed!
        // if (platform) {
        //   platform.showMessageBoxSync({
        //     type: 'error',
        //     title: 'שגיאה בסורק',
        //     message: 'סריקה נכשלה, בדוק שהכל תקין עם הסורק!',
        //   })
        // }
        throw new Error(`הסריקה נכשלה. נא לבדוק שהכל תקין עם הסורק. השגיאה שהתקבלה היא: "${error?.message}"`)
    }
}
