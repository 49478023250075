import * as yup from 'yup'

const companyTypeOptions = [
    {
        label: 'חברה ציבורית',
        value: 'Public_Company',
    },
    {
        label: 'חברה פרטית',
        value: 'Private_Company',
    },
    {
        label: 'עוסק מורשה',
        value: 'Authorized_Contractor',
    },
    {
        label: 'עוסק פטור',
        value: 'Exempt_Contractor',
    },
    {
        label: 'ארגון ללא מטרות רווח',
        value: 'Non_Profit_Organization',
    },
]

const reportingPeriodTypeOptions = [
    {
        label: 'אחת לחודש',
        value: 'OnceInAMonth',
    },
    {
        label: 'אחת לחודשיים',
        value: 'OnceInTwoMonths',
    },
]

const schema = yup.object({
    companyName: yup
        .string()
        .label('שם החברה')
        .required('נא להזין שם החברה')
        .meta({
            section: 'basic',
        }),
    totalEntries: yup
        .number()
        .label('סה״כ רשומות'),
    invalidEntries: yup
        .number()
        .label('לא תקינות'),
    companyId: yup
        .string()
        .label('ח״פ/ע״מ/ת״ז')
        .required('נא להזין מזהה חברה')
        .meta({
            section: 'basic',
        }),
    city: yup
        .string()
        .label('עיר')
        // .required('נא להזין עיר')
        .meta({
            section: 'address',
        }),
    street: yup
        .string()
        .label('רחוב')
        // .required('נא להזין רחוב')
        .meta({
            section: 'address',
        }),
    addressNumber: yup
        .string()
        .label('מספר הכתובת')
        // .required('נא להזין מספר הכתובת')
        .meta({
            section: 'address',
        }),
    poBox: yup
        .string()
        .label('תא דואר')
        // .required('נא להזין תא דואר')
        .meta({
            section: 'address',
        }),
    companyType: yup
        .string()
        .label('סוג החברה')
        .required('נא להזין סוג החברה')
        .meta({
            section: 'taxes',
            options: companyTypeOptions,
            // options: [
            //   {
            //     label: 'חברה ציבורית',
            //     value: 'Public_Company',
            //   },
            //   {
            //     label: 'חברה פרטית',
            //     value: 'Private_Company',
            //   },
            //   {
            //     label: 'עוסק מורשה',
            //     value: 'Authorized_Contractor',
            //   },
            //   {
            //     label: 'עוסק פטור',
            //     value: 'Exempt_Contractor',
            //   },
            //   {
            //     label: 'ארגון ללא מטרות רווח',
            //     value: 'Non_Profit_Organization',
            //   },
            // ],
        })
        // .oneOf([
        //   'Public_Company',
        //   'Private_Company',
        //   'Authorized_Contractor',
        //   'Exempt_Contractor',
        //   'Non_Profit_Organization',
        // ])
        .oneOf(companyTypeOptions.map((option) => option.value)),
    // [
    //   'Public_Company',
    //   'Private_Company',
    //   'Authorized_Contractor',
    //   'Exempt_Contractor',
    //   'Non_Profit_Organization',
    // ]),
    reportingPeriodType: yup
        .string()
        .label('תקופת הדיווח')
        .required('נא להזין תקופת הדיווח')
        .meta({
            section: 'taxes',
            options: reportingPeriodTypeOptions,
            // [
            //   {
            //     label: 'אחת לחודש',
            //     value: 'OnceInAMonth',
            //   },
            //   {
            //     label: 'אחת לחודשיים',
            //     value: 'OnceInTwoMonths',
            //   },
            // ]
        })
        .oneOf(reportingPeriodTypeOptions.map((option) => option.value)),
    // [
    //   'OnceInAMonth',
    //   'OnceInTwoMonths',
    // ]),
})

export default schema
