import React from 'react'
import Page from '../pages/Page'

function NoEditor({
    document,
    documentCommands,
}) {
    const commands = [
        ...documentCommands,
    ]

    const page = (
        <Page
            title={`מסמך ${document.id}`}
            status={document.status}
            commands={commands}
        >
            לא נמצא עורך למסמך
        </Page>
    )

    return page
}

export default NoEditor
