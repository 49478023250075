import React from 'react'
import {
    createHashRouter,
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom'

import routes from '../../routes'

const routers = {
    hash: createHashRouter,
    browser: createBrowserRouter,
}

const HybridRouter = ({ type }) => {
    const createRouter = routers[type]

    const router = createRouter(routes)

    return <RouterProvider router={router} />
}

export default HybridRouter
