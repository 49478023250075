import { Icon } from '@mui/material'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

function Tips({ tips }) {
    return (
        <MDBox>
            {tips && tips.map((tip, index) => (
                <MDBox display="flex" alignItems="center" mt={3} gap={1} key={index}>
                    <Icon color="info">info</Icon>
                    <MDTypography color="info" variant="caption">{tip}</MDTypography>
                </MDBox>
            ))}
        </MDBox>
    )
}

export default Tips

