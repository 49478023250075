/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { forwardRef } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// Custom styles for the MDBadge
import MDBadgeRoot from 'components/MDBadge/MDBadgeRoot'

const MDBadge = forwardRef(
    (
        {
            color = 'info',
            variant = 'gradient',
            size = 'sm',
            circular = false,
            indicator = false,
            border = false,
            container = false,
            children = false,
            ...rest
        },
        ref,
    ) => (
        <MDBadgeRoot
            {...rest}
            ownerState={{ color, variant, size, circular, indicator, border, container, children }}
            ref={ref}
            color="default"
        >
            {children}
        </MDBadgeRoot>
    ),
)

// Typechecking props of the MDBadge
MDBadge.propTypes = {
    color: PropTypes.oneOf([
        'primary',
        'secondary',
        'info',
        'success',
        'warning',
        'error',
        'light',
        'dark',
    ]),
    variant: PropTypes.oneOf(['gradient', 'contained']),
    size: PropTypes.oneOf(['xs', 'sm', 'md', 'lg']),
    circular: PropTypes.bool,
    indicator: PropTypes.bool,
    border: PropTypes.bool,
    children: PropTypes.node,
    container: PropTypes.bool,
}

export default MDBadge
