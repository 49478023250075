import { useContext, useMemo } from 'react'
import AuthContext from 'units/auth/AuthContext'

export default function useName() {
    const defaultName = 'אורח'

    const { user } = useContext(AuthContext)
    const userId = user?.id

    const fullName = useMemo(
        () => {
            if (user) {
                return [user.firstName, user.lastName].join(' ')
            }
            else {
                return defaultName
            }
        },
        [userId],
    )

    return fullName
}
