/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-type is a library for typechecking of props
import PropTypes from 'prop-types'
import classNames from 'classnames'

// formik components
import { ErrorMessage, Field, useFormikContext } from 'formik'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'

import './FormField.styles.scss'
import { Autocomplete } from '@mui/material'

function FormField({
    label,
    name,
    type,
    disabled,
    options,
    className,
    isInvalid,
    validationMessage,
    ...rest
}) {

    const { values, setFieldValue } = useFormikContext()

    const classes = classNames({
        'form-field': true,
        ...className,
    })

    const createField = () => {
        if (type === 'select' || options) {
            const optionValues = options.map((option) => option.value)
            return (
                <Autocomplete
                    // options={values[name] ? [values[name], ...optionValues] : optionValues}
                    options={optionValues}
                    getOptionLabel={
                        (value) => {
                            const option = options.find((option) => option.value === value)
                            const label = option?.label || 'נא לבחור ערך'
                            // return `${value}: ${label}`
                            return label
                        }
                    }
                    isOptionEqualToValue={(option, value) => {
                        return value === '' || option === value
                    }}
                    value={values[name]}
                    onChange={(e, value) => {
                        setFieldValue(name, value)
                    }}
                    renderInput={(params) => (
                        <Field
                            {...rest}
                            {...params}
                            name={name}
                            type={'select'}
                            disabled={disabled}
                            as={MDInput}
                            variant="filled"
                            label={label}
                            fullWidth
                            className={classes}
                        />
                    )}
                />
            )
        }
        else {
            return (
                <Field
                    {...rest}
                    name={name}
                    type={type}
                    disabled={disabled}
                    as={MDInput}
                    variant="filled"
                    label={label}
                    fullWidth
                    className={classes}
                />
            )
        }
    }

    const field = createField()

    return (
        <MDBox mb={1.5}>
            {field}
            <MDBox mt={0.75}>
                <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                    <ErrorMessage name={name} />
                </MDTypography>
            </MDBox>
        </MDBox>
    )
}

// typechecking props for FormField
FormField.propTypes = {
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
}

export default FormField
