const vouchersPathLabels = (voucher) => {
    let path = voucher.path
    switch (path) {
        case 'barcode':
            return 'שובר ברקוד'
        case 'rejected_barcode':
            return 'שובר ברקוד דחוי'
        case 'magnetic_voucher':
            return 'שובר מגנטי'
        case 'rejected_magnetic_voucher':
            return 'שובר מגנטי דחוי'
        case 'optic_voucher':
            return 'שובר אופטי'
        case 'zero_payment':
            return 'שובר אפס'
        default:
            return 'שובר לא מזוהה'
    }
}
export default vouchersPathLabels
