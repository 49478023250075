export function bytesToFile(bytes, fileName, mime) {
    console.log('bytesToFile', bytes, fileName, mime)
    const bstr = atob(bytes)
    console.log('bstr', bstr)
    let n = bstr.length
    console.log('n', n)
    const u8arr = new Uint8Array(n)
    console.log('u8arr', u8arr)
    while (n--) {
        console.log('n', n)
        u8arr[n] = bstr.charCodeAt(n)
        console.log('u8arr', u8arr)
    }
    const file = new File([u8arr], fileName, { type: mime })
    console.log('file', file)
    return file
}

export function dataUrlToFile(dataUrl, fileName) {
    console.log('dataUrlToFile', dataUrl, fileName)
    const [mimeInfo, ...bytes] = dataUrl.split(',')
    console.log('mimeInfo', mimeInfo, 'bytes', bytes)
    const mime = mimeInfo.match(/:(.*?);/)[1]
    console.log('mime', mime)
    const file = bytesToFile(bytes, fileName, mime)
    console.log('file', file)
    return file
}

export function dataUrlToFileOld(dataUrl, fileName) {
    console.log('dataUrlToFileOld', dataUrl, fileName)
    const arr = dataUrl.split(',')
    console.log('arr', arr)
    const mime = arr[0].match(/:(.*?);/)[1]
    console.log('mime', mime)
    const bstr = atob(arr[1])
    console.log('bstr', bstr)
    let n = bstr.length
    console.log('n', n)
    const u8arr = new Uint8Array(n)
    console.log('u8arr', u8arr)
    while (n--) {
        console.log('n', n)
        u8arr[n] = bstr.charCodeAt(n)
        console.log('u8arr', u8arr)
    }
    const file = new File([u8arr], fileName, { type: mime })
    console.log('file', file)
    return file
}

export async function fileToDataUrl(file) {
    console.log('fileToDataUrl', file)
    const reader = new FileReader()
    console.log('reader', reader)

    return new Promise((resolve, reject) => {
        console.log('in promise')
        reader.addEventListener(
            'load',
            () => {
                console.log('load resolved')
                resolve(reader.result)
            },
            false,
        )

        reader.readAsDataURL(file)
    })
}

export function determineImageName(image) {

}

export function prepareFormData(values, imagesSpec, platform) {
    const { images: imagesValue = {}, ...rest } = values

    const formData = new FormData()
    if (Object.entries(rest).length) {
        const json = JSON.stringify(rest)
        formData.append('data', json)
    }

    Object.entries(imagesValue).forEach(([imageType, image]) => {
        if (image instanceof File) {
            const imageTypeParts = imageType.split('_')
            const side = imageTypeParts[1] === 'Front' ? 'F' : 'R'
            const type = imageTypeParts[2] === 'tif' ? '1' : '2'
            const name = `files.${side}${type}`
            formData.append(name, image, image.name)
        }
        else {
            const imageSpec = imagesSpec.find((imageSpec) => imageSpec.fieldName === imageType)
            const filePath = image.url
            if (filePath) {
                const name = `files.${imageSpec.key}`

                const file = platform.readFileSync(filePath)
                const blob = new Blob([file], { type: `image/${imageSpec.imageType}` })

                const filePathParts = filePath.split(/\\|\//)
                const fileName = filePathParts[filePathParts.length - 1]

                formData.append(name, blob, { filename: fileName })
            }
        }
    })

    return formData
}

export async function addImagesToFormData(images, formData) {
    let imageCount = 0
    if (images) {
        const payloadImagesAsArray = Object.entries(images)
        payloadImagesAsArray.forEach(async (imageAsArray) => {
            const imageType = imageAsArray[0]
            const imageObj = imageAsArray[1]
            const imageTypeParts = imageType.split('_')
            const side = imageTypeParts[1] === 'Front' ? 'F' : 'R'
            const type = imageTypeParts[2] === 'tif' ? '1' : '2'
            const name = `files.${side}${type}`

            let dataUrl
            if (imageObj instanceof File && imageObj.size > 0) {
                // dataUrl = await fileToDataUrl(imageObj, imageTypeParts[2])
                // console.log(dataUrl)
                // formData.append()
            }
            else {

                const filePath = imageObj.url
                const file = platform.readFileSync(filePath)

                imageCount++
                const filePathParts = filePath.split('\\')
                const fileName = filePathParts[filePathParts.length - 1]
                dataUrl = `data:image/${imageTypeParts[2]};base64,${file.toString('base64')}`
                const fileObject = dataUrlToFile(dataUrl, fileName)
                formData.append(name, fileObject)
            }

            // else if (file?.length > 0) {
            //   formData.append(name, imageObj)
            // }
        })
        if (imageCount > 0) {
            formData.append('data', JSON.stringify({}))
        }
    }
}

// export function addImagesToFormData(images, formData) {
//   let imageCount = 0
//   if (images) {
//     const payloadImagesAsArray = Object.entries(images)
//     payloadImagesAsArray.forEach((imageAsArray) => {
//       const imageType = imageAsArray[0]
//       const imageObj = imageAsArray[1]
//       const imageTypeParts = imageType.split('_')
//       const side = imageTypeParts[1] === 'Front' ? 'F' : 'R'
//       const type = imageTypeParts[2] === 'tif' ? '1' : '2'
//       const filePath = imageObj.url
//       if (filePath) {
//         imageCount++
//         const name = `files.${side}${type}`
//         const file = platform.readFileSync(filePath)
//         const filePathParts = filePath.split('\\')
//         const dataUrl = `data:image/${imageTypeParts[2]};base64,${file.toString('base64')}`
//         const fileName = filePathParts[filePathParts.length - 1]
//         const fileObject = dataUrlToFile(dataUrl, fileName)
//         formData.append(name, fileObject)
//       }
//     })
//     if (imageCount > 0) {
//       formData.append('data', JSON.stringify({}))
//     }
//   }
// }
