import { createContext, useContext, useState } from 'react'
import moment from 'moment'

const NotificationContext = createContext()

export const useNotificationContext = () => {
    return useContext(NotificationContext)
}

const defaultNotification = {
    type: 'info',
    content: '',
    open: false,
    close: undefined,
    duration: 10000,
}

const typeInfos = {
    success: {
        title: 'הפעולה הצליחה',
        color: 'success',
        icon: 'success',
    },
    error: {
        title: 'ארעה שגיאה',
        color: 'error',
        icon: 'error',
        duration: 30000,
    },
    default: {
        title: 'VIPost',
        color: 'info',
        icon: 'info',
    },
}

export const NotificationProvider = ({ children }) => {
    const [notifications, setNotifications] = useState([])

    const addNotification = (notification) => {
        const { type } = notification
        const typeInfo = typeInfos.hasOwnProperty(type) ? typeInfos[type] : typeInfos.default

        const now = moment()

        const newNotification = {
            ...defaultNotification,
            ...typeInfo,
            ...notification,
            id: now.unix(),
            time: now.format('HH:mm:ss'),
        }
        setNotifications((previousNotifications) => [...previousNotifications, newNotification])
    }

    const removeNotification = (id) => {
        setNotifications((previousNotifications) =>
            previousNotifications.filter((notification) => notification.id !== id),
        )
    }

    return (
        <NotificationContext.Provider value={{ notifications, addNotification, removeNotification }}>
            {children}
        </NotificationContext.Provider>
    )
}
