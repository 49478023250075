import * as yup from 'yup'
import {
    firstMeta,
    numericMeta,
    forgetValueMeta,
    requiredCurrencyField,
    requiredNumericStringField,
} from './common'

const asmachtaMeta = {
    ...numericMeta,
    ...forgetValueMeta,
}

const schema = yup.object().shape({
    currency: requiredCurrencyField(yup, 'סכום רשום על השובר', firstMeta),
    asmachtaA: requiredNumericStringField(yup, 'אסמכתה א\'', asmachtaMeta),
    beneficiaryCode: requiredNumericStringField(yup, 'קוד מוטב', numericMeta),
    asmachtaB: requiredNumericStringField(yup, 'אסמכתא ב\'', asmachtaMeta),
})

export default schema
