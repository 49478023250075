/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 The pxToRem() function helps you to convert a px unit into a rem unit,
 */

function pxToRem(number, baseNumber = 16) {
    return `${number / baseNumber}rem`
}

export default pxToRem
