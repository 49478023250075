export function createCustomCellWithOptions(field) {
    const options = field.meta?.options
    const customCell =
        options == null
            ? null
            : {
                Cell: (cell) => {
                    const option = options.find((option) => option.value === cell.value)
                    return option?.label
                },
            }
    return customCell
}
