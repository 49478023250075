import MDSnackbar from 'components/MDSnackbar'
import MDBox from 'components/MDBox'
import { useNotificationContext } from './NotificationContext'

const Notification = () => {
    const {
        notifications,
        removeNotification,
    } = useNotificationContext()

    return (
        <MDBox>
            <div className="notifications">
                {notifications.map((notification) => {
                    return (
                        <MDBox key={notification.id}>
                            <MDSnackbar
                                color={notification.color}
                                icon={notification.icon}
                                title={notification.title}
                                content={notification.content || ''}
                                dateTime={notification.time}
                                open={true}
                                close={() => removeNotification(notification.id)}
                                duration={notification.duration}
                                bgWhite={false}
                            />
                        </MDBox>
                    )
                })}
            </div>
        </MDBox>
    )
}

export default Notification
