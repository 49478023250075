/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// import { useEffect } from 'react'
import React, { useEffect, useState } from 'react'

// react-router-dom components
import { Outlet, useLocation } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Material Dashboard 2 PRO React context
import { setLayout, useMaterialUIController } from 'context/material-ui'

// Images
// import brandWhite from 'assets/images/logo.png'
// import brandDark from 'assets/images/logo.png'

// Material Dashboard 2 PRO React examples
import Sidenav from 'examples/Sidenav'
// import Configurator from 'examples/Configurator'

import DashboardNavbar from 'examples/Navbars/DashboardNavbar'
import Footer from 'examples/Footer'

function DashboardLayout() {
    const [controller, dispatch] = useMaterialUIController()
    const {
        miniSidenav,
        direction,
        layout,
        openConfigurator,
        sidenavColor,
        transparentSidenav,
        whiteSidenav,
        darkMode,
    } = controller
    const { pathname } = useLocation()

    useEffect(() => {
        setLayout(dispatch, 'dashboard')
    }, [pathname])

    const [onMouseEnter, setOnMouseEnter] = useState(false)

    // Open sidenav when mouse enter on mini sidenav
    const handleOnMouseEnter = () => {
        if (miniSidenav && !onMouseEnter) {
            setMiniSidenav(dispatch, false)
            setOnMouseEnter(true)
        }
    }

    // Close sidenav when mouse leave mini sidenav
    const handleOnMouseLeave = () => {
        if (onMouseEnter) {
            setMiniSidenav(dispatch, true)
            setOnMouseEnter(false)
        }
    }

    // // Change the openConfigurator state
    // const handleConfiguratorOpen = () => setOpenConfigurator(dispatch, !openConfigurator)

    // Setting the dir attribute for the body element
    useEffect(() => {
        document.body.setAttribute('dir', direction)
    }, [direction])

    // let selectedTheme
    // if (darkMode) {
    //   selectedTheme = direction === 'rtl' ? themeDarkRTL : themeDark
    // }
    // else {
    //   selectedTheme = direction === 'rtl' ? themeRTL : theme
    // }

    return (
        <MDBox
            sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
                px: 3,
                py: 0,
                position: 'relative',
                height: '100%',

                [breakpoints.up('xl')]: {
                    marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
                    transition: transitions.create(['margin-left', 'margin-right'], {
                        easing: transitions.easing.easeInOut,
                        duration: transitions.duration.standard,
                    }),
                },
            })}
        >
            <Sidenav
                color={sidenavColor}
                // brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
                brandName="VIPost"
                subtitle="מערכת תשלום - בנק הדואר"
                // routes={routes}
                onMouseEnter={handleOnMouseEnter}
                onMouseLeave={handleOnMouseLeave}
            />
            <Outlet />
        </MDBox>
    )
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
    // children: PropTypes.node.isRequired,
}

export default DashboardLayout
