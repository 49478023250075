import { React, useContext, useEffect, useState } from 'react'
import { useQuery } from '@tanstack/react-query'
import useQueryLibrary from '../../hooks/useQueryLibrary'
import FetchContext from '../../context/fetch/FetchContext'
import Page from '../pages/Page'
import CompanyCards from './CompanyCards'
import CompaniesTable from './CompaniesTable'

const DISPLAY_MODE_CARDS = 'cards'
const DISPLAY_MODE_TABLE = 'table'

export default function CompaniesPage(props) {
    const [companies, setCompanies] = useState(null)
    const [search, setSearch] = useState('')
    const [displayMode, setDisplayMode] = useState(DISPLAY_MODE_TABLE)

    const queryLibrary = useQueryLibrary()

    const fetcher = useContext(FetchContext)

    const {
        data,
        isFetching,
        isLoading,
    } = useQuery({
        queryKey: queryLibrary.companiesQuery(search),
        queryFn: async () => {
            const data = await fetcher.fetchCompaniesForCompaniesPage(search)
            return data
        },
        enabled: fetcher.hasAuthorizationHeader,
    })

    useEffect(() => {
        if (data != null) {
            setCompanies(data)
        }
    }, [data])

    const commands = [
        {
            scheme: 'link',
            label: 'חברה חדשה',
            to: '/companies/add',
            position: 'start',
        },
        {
            scheme: 'input',
            label: 'חיפוש',
            placeholder: 'חיפוש',
            value: search,
            onChange: (e) => {
                setSearch(e.target.value)
            },
            position: 'end',
        },
        {
            scheme: 'button',
            icon: displayMode === DISPLAY_MODE_CARDS ? 'table_rows' : 'view_module',
            onClick: (e) => {
                setDisplayMode((previous) => {
                    return previous === DISPLAY_MODE_CARDS ? DISPLAY_MODE_TABLE : DISPLAY_MODE_CARDS
                })
            },
            position: 'end',
            color: 'secondary',
        },
    ]

    function renderCompanies(displayMode, companies) {
        if (displayMode === DISPLAY_MODE_CARDS) {
            return <CompanyCards {...props} companies={companies} />
        }
        else if (displayMode === DISPLAY_MODE_TABLE) {
            return <CompaniesTable companies={companies} />
        }
        else {
            return `unrecognized display mode: ${displayMode}`
        }
    }

    return (
        <Page
            commands={commands}
            className="companies"
            title="החברות שלי"
            busy={isFetching || isLoading}
        >
            {companies?.length > 0
                ? renderCompanies(displayMode, companies)
                : (
                    <div className="companies-empty">
                        <p>לא נמצאו חברות.</p>
                        <p>צרו חברה חדשה כדי להתחיל.</p>
                    </div>
                )
            }
        </Page>
    )
}
