import { useContext, useEffect, useState } from 'react'
import moment from 'moment'
import { Form, Formik } from 'formik'
import * as yup from 'yup'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import AuthContext from 'units/auth/AuthContext'
import Scanner from 'units/scanner/Scanner.component'
import PlatformContext from 'context/platform/PlatformContext'
// import { palette } from 'assets/theme'
import colors from 'assets/theme/base/colors'
import Page from '../pages/Page'
// import FormField from 'components/forms/FormField'
import AppFormContainer from 'components/forms/AppFormContainer'
import AppFormSection from 'components/forms/AppFormSection'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDBadge from 'components/MDBadge'
// import AppForm from 'components/forms/AppForm'
// import { addImagesToFormData } from 'helpers/file.helper'
import Tips from 'components/Tips'
import { fileToDataUrl, prepareFormData } from 'helpers/file.helper'
import schema from 'schemas/check'

yup.setLocale({
    mixed: {
        default: 'field.validation.invalid',
    },
    number: {
        min: ({ min }) => ({ key: 'field.validation.number.too_small', values: { min } }),
        max: ({ max }) => ({ key: 'field.validation.number.too_big', values: { max } }),
        lessThan: ({ value }) => ({ key: 'field.validation.number', values: { value } }),
        moreThan: ({ value }) => ({ key: 'field.validation.number', values: { value } }),
        positive: ({ value }) => ({ key: 'field.validation.number.positive', values: { value } }),
        negative: ({ value }) => ({ key: 'field.validation.number.negative', values: { value } }),
        integer: ({ value }) => ({ key: 'field.validation.number.i', values: { value } }),
        truncate: ({ value }) => ({ key: 'field.validation.number.truncate', values: { value } }),
        round: ({ value }) => ({ key: 'field.validation.number.round', values: { value } }),
    },
})

const imagesSpec = [
    {
        key: 'F1',
        fieldName: 'Check_Front_tif',
        imageType: 'tif',
        label: 'תמונה קדמית מסוג TIF',
        canDisplayImage: false,
    },
    {
        key: 'R1',
        fieldName: 'Check_Back_tif',
        imageType: 'tif',
        label: 'תמונה אחורית מסוג TIF',
        canDisplayImage: false,
    },
    {
        key: 'F2',
        fieldName: 'Check_Front_jpg',
        imageType: 'jpg',
        label: 'תמונה קדמית מסוג JPG',
        canDisplayImage: true,
    },
    {
        key: 'R2',
        fieldName: 'Check_Back_jpg',
        imageType: 'jpg',
        label: 'תמונה אחורית מסוג JPG',
        canDisplayImage: true,
    },
]

function CheckUploader({
    document,
    mode,
    documentCommands,
    busy,
    readOnly,
    setDocumentDetailMutation,
}) {
    const { token } = useContext(AuthContext)

    const [initialValues, setInitialValues] = useState({
        number: '',
        bank: '',
        branch: '',
        account: '',
        currency: '',
        schedule: 'immediate',
    })

    const defaultImages = imagesSpec.reduce((result, imageSpec) => ({
        ...result,
        [imageSpec.fieldName]: null,
    }), {})
    const [images, setImages] = useState(defaultImages)

    const [validated, setValidated] = useState(false)
    const [disableDeferredOption, setDisableDeferredOption] = useState(false)

    const platform = useContext(PlatformContext)

    useEffect(() => {
        setInitialStateFunc()
    }, [mode])

    const createImagesObject = (imagesDetails) => {
        const imagesObject = imagesSpec.reduce((result, imageSpec) => {
            const imageDetailFound = imagesDetails.find(imageDetail => imageDetail.type === imageSpec.fieldName)
            result[imageSpec.fieldName] = {
                name: imageSpec.fieldName,
                url: imageDetailFound ? `${process.env.REACT_APP_STRAPIURL_BASE}${imageDetailFound.file.url}?jwt=${token}` : null,
                imageType: imageSpec.imageType,
            }
            return result
        }, {})

        return imagesObject
    }

    const setInitialStateFunc = () => {
        if (mode !== 'new' && document.check) {
            const { check, schedule } = document

            const objectToSet = {
                number: check.number,
                bank: check.bank,
                branch: check.branch,
                account: check.account,
                currency: check.currency,
                schedule,
            }
            setInitialValues(objectToSet)

            const documentImagesObject = createImagesObject(document.images)
            const editedImagesObject =
                Object.entries(images)
                    .filter(([, value]) => value instanceof File)
                    .reduce((result, [key, value]) => {
                        return {
                            ...result,
                            [key]: value,
                        }
                    }, {})
            setImages({
                ...documentImagesObject,
                ...editedImagesObject,
            })
        }
    }

    // const reset = () => {
    //   setFormData({
    //     ...initialValues,
    //   })
    //   setPayload({
    //     images: {},
    //   })
    // }

    // const validate = (event) => {
    //   const form = event.currentTarget;
    //   if (form.checkValidity() === false) {
    //     event.preventDefault();
    //     event.stopPropagation();
    //   }

    //   setValidated(true)
    // }

    const handleSubmit = async (values) => {
        const data = prepareFormData(values, imagesSpec, platform)
        setDocumentDetailMutation.mutate({
            data,
            endpoint: 'Check',
        })
    }

    const handleFileChange = async (event, imageSpec) => {
        const file = event.target.files[0]
        const { fieldName } = imageSpec
        if (file) {
            const dataUrl = await fileToDataUrl(file)

            setImages({
                ...images,
                [fieldName]: {
                    imageType: imageSpec.imageType,
                    name: fieldName,
                    url: dataUrl,
                },
            })
        }
    }

    // const insertScannedData = (rawScannerText) => {
    //   try {
    //     const scannerParser = new ScannerParser(platform)
    //     const result = scannerParser.parseScannerText(rawScannerText, baseLocation)
    //     const {
    //       fieldsData,
    //       imagesObject,
    //       missingImages,
    //     } = result

    //     // setFormData({
    //     //   ...formData,
    //     //   number: fieldsData.number,
    //     //   bank: fieldsData.bank,
    //     //   branch: fieldsData.branch,
    //     //   account: fieldsData.account,
    //     //   schedule: fieldsData.schedule,
    //     //   images: imagesObject,
    //     // })
    //     // setPayload({
    //     //   ...payload,
    //     //   number: fieldsData.number,
    //     //   bank: fieldsData.bank,
    //     //   branch: fieldsData.branch,
    //     //   account: fieldsData.account,
    //     //   schedule: fieldsData.schedule,
    //     //   images: imagesObject,
    //     // })

    //     if (missingImages) {
    //       platform.showMessageBoxSync({
    //         type: 'warning',
    //         buttons: [],
    //         title: 'קבצים לא התקבלו',
    //         message: 'אחד (או יותר) מהקבצים שהסורק אמור לייצר לא נמצאו, יש לחפש ולהעלות אותם ידנית.',
    //       })
    //     }
    //   }
    //   catch(error) {
    //     if(error instanceof ScannerParserError) {
    //       platform.showMessageBoxSync({
    //         type: 'error',
    //         buttons: [],
    //         title: error.title,
    //         message: error.message,
    //       })
    //     }
    //     else {
    //       platform.showMessageBoxSync({
    //         type: 'error',
    //         buttons: [],
    //         title: 'ארעה שגיאה',
    //         message: error.message,
    //       })
    //     }
    //   }
    // }

    // const doActivateScanner = async () => {
    //   const scannedData = await activateScanner(platform)
    //   console.log('scannedData', scannedData)
    //   if (scannedData) {
    //     insertScannedData(scannedData)
    //   }
    // }

    // const onActivateScanner = (e) => {
    //   e.preventDefault()
    //   e.stopPropagation()
    //   // doActivateScanner()
    //   // // const scannedData = await activateScanner()
    //   // // console.log('scannedData', scannedData)
    //   // // if (scannedData) {
    //   // //   insertScannedData(scannedData)
    //   // // }
    // }

    const [scannerTrigger, setScannerTrigger] = useState(false)

    const mimicScanner = async (mode) => {
        const modes = ['valid', 'invalid', 'empty']
        if (!modes.includes(mode)) {
            console.log('not a valid mode', mode)
        }

        const route = `/tools/mimic${mode}checkscan`
        setScannerTrigger(route)

        // try {
        //   const res = await fetch({
        //     method: 'get',
        //     path: route,
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     responseHandler: (response) => response,
        //     rethrow: true,
        //   })

        //   // File uploaded successfully!
        //   // insertScannedData(res.data)
        // }
        // catch (error) {
        //   // File upload failed!
        //   console.error(`couldn't fetch mimic of scan`, error)
        // }
    }

    // const handleClick = (e, handler) => {
    //   e.preventDefault()
    //   handler()
    // }

    const commands = [
        ...documentCommands,
        {
            label: 'הפעלת סורק',
            onClick: (e) => {
                setScannerTrigger(true)
                // onActivateScanner(e)
            },
            position: 'start',
            color: 'secondary',
            disabled: busy || readOnly || scannerTrigger,
        },
        // {
        //   label: 'מק',
        //   // to: null,
        //   onClick: () => {
        //     mimicScanner('fakemac')
        //   },
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
        // {
        //   label: 'חיקוי סורק תקין',
        //   // to: null,
        //   onClick: () => mimicScanner('valid'),
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
        // {
        //   label: 'חיקוי סורק לא תקין',
        //   // to: null,
        //   onClick: () => mimicScanner('invalid'),
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
        // {
        //   label: 'חיקוי סורק ריק',
        //   // to: null,
        //   onClick: () => mimicScanner('empty'),
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
        // {
        //   label: 'הפעלת סורק',
        //   onClick: (e) => handleClick(e, () => onActivateScanner()),
        //   position: 'start',
        // },
        // {
        //   label: 'חיקוי סורק תקין',
        //   onClick: (e) => handleClick(e, () => mimicScanner('valid')),
        //   position: 'end',
        //   color: 'secondary',
        // },
        // {
        //   label: 'חיקוי סורק לא תקין',
        //   onClick: (e) => handleClick(e, () => mimicScanner('invalid')),
        //   position: 'end',
        //   color: 'secondary',
        // },
        // {
        //   label: 'חיקוי סורק ריק',
        //   onClick: (e) => handleClick(e, () => mimicScanner('empty')),
        //   position: 'end',
        //   color: 'secondary',
        // },
    ]

    // const MyField = ({
    //   label,
    //   isInvalid,
    //   validationMessage,
    //   // validated,
    //   ...props
    // }) => {
    //   const [field, meta, helpers] = useField(props)
    //   return (
    //     <TextField
    //       variant="filled"
    //       label={label}
    //       {...field}
    //       {...props}
    //     />
    //     // <div>
    //     //   <label>
    //     //     {label}
    //     //   </label>
    //     //   <input {...field} {...props} />
    //     //   {meta.touched && meta.error ? (
    //     //     <div className="error">{meta.error}</div>
    //     //   ) : null}
    //     // </div>
    //   )
    // }

    const now = moment()
    const dayOfMonth = now.date()
    const enableDeferredOption = dayOfMonth < 15

    // const paymentSchedulingOptions = [
    //   {
    //     id: 1,
    //     label: 'תשלום שוטף',
    //     disabled: false,
    //   },
    //   {
    //     id: 2,
    //     label: 'תשלום דחוי ל-15 לחודש',
    //     disabled: !enableDeferredOption,
    //   },
    // ]

    const schemaDescription = schema.describe()
    const { fields } = schemaDescription

    const page = (
        <Page
            title={`שיק ${document?.id}`}
            status={document.status}
            commands={commands}
        >
            <Formik
                onSubmit={async (values, action) => {
                    await handleSubmit(values)
                }}
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={initialValues}
            >
                {({
                    handleChange,
                    values,
                    setFieldValue,
                }) => {
                    function updateItems(items) {
                        if (items) {
                            const fields = {
                                number: items[0],
                                bank: items[1],
                                branch: items[2].slice(0, 3),
                                account: items[3],
                            }
                            // if (fields) {
                            Object.entries(fields).forEach(([key, value]) => {
                                setFieldValue(key, value)
                            })
                            // }
                        }
                    }

                    // function updateImages(imagesObject) {
                    //   Object.entries(imagesObject).forEach(([key, value]) => {
                    //     setFieldValue(`images.${key}`, value)
                    //   })
                    //   setImages(imagesObject)
                    // }

                    return (
                        <Form id="form">
                            <Scanner
                                scannerTrigger={scannerTrigger}
                                setScannerTrigger={setScannerTrigger}
                                setFieldValue={setFieldValue}
                                setImages={setImages}
                                imagesSpec={imagesSpec}
                                updateItems={updateItems}
                                // updateImages={updateImages}
                            />
                            <AppFormContainer>
                                <AppFormSection
                                    title="פרטי השיק"
                                    section="details"
                                    fields={fields}
                                    disabled={readOnly}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                />
                                <AppFormSection title="תמונות" xs={12} md={12} lg={8}>
                                    <Grid container item columns={8}>
                                        {imagesSpec.map((imageSpec, index) => {
                                            const { label, fieldName, canDisplayImage } = imageSpec

                                            const image = images[fieldName]

                                            const url = image?.url

                                            let badgeMessage = null
                                            let badgeColor = null
                                            if (url == null) {
                                                badgeMessage = 'לא נבחרה תמונה'
                                                badgeColor = 'warning'
                                            }
                                            else if (image?.imageType === 'tif') {
                                                badgeMessage = 'קיימת תמונה, אבל אי אפשר להציג אותה'
                                                badgeColor = 'success'
                                            }

                                            let src
                                            if (url != null) {
                                                if (url.includes('://')) {
                                                    src = url
                                                }
                                                else if (url.startsWith('/')) {
                                                    src = `file://${url}`
                                                }
                                                else {
                                                    src = url
                                                }
                                            }

                                            return (
                                                <Grid item key={index} xs={4}>
                                                    <label className="btn btn-primary" htmlFor={fieldName}>
                                                        <MDBox
                                                            mx={1}
                                                            mb={2}
                                                            py={1}
                                                            px={2}
                                                            bgColor={colors.grey['200']}
                                                            shadow="lg"
                                                            sx={{
                                                                borderRadius: 2,
                                                                cursor: readOnly ? 'not-allowed' : 'pointer',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <MDTypography variant="h3" pb={1}>
                                                                {label}
                                                            </MDTypography>
                                                            {badgeMessage && (
                                                                <MDBadge
                                                                    badgeContent={badgeMessage}
                                                                    color={badgeColor}
                                                                    circular={true}
                                                                    variant="contained"
                                                                    size="lg"
                                                                />
                                                            )}
                                                            {canDisplayImage && image?.url && (
                                                                <img alt={label} src={src} />
                                                            )}
                                                            <MDBox>
                                                                <MDBox>
                                                                    <input
                                                                        type="file"
                                                                        name={fieldName}
                                                                        id={fieldName}
                                                                        disabled={readOnly}
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`images.${fieldName}`, e.currentTarget.files[0])
                                                                            handleFileChange(e, imageSpec)
                                                                        }}
                                                                    />
                                                                </MDBox>
                                                            </MDBox>
                                                            <MDTypography
                                                                color="primary"
                                                                pt={1}
                                                                sx={{
                                                                    textAlign: 'end',
                                                                }}
                                                            >
                                                                בחירת קובץ באופן ידני
                                                            </MDTypography>
                                                        </MDBox>
                                                    </label>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </AppFormSection>
                                <AppFormSection title="תזמון" xs={12} md={6} lg={4}>
                                    <RadioGroup
                                        name="schedule"
                                        onChange={handleChange}
                                    >
                                        <FormControlLabel
                                            value="immediate"
                                            control={<Radio />}
                                            label="תשלום שוטף"
                                            name="schedule"
                                            checked={values.schedule === 'immediate'}
                                            disabled={readOnly}
                                        />
                                        <FormControlLabel
                                            value="deferred"
                                            control={<Radio />}
                                            label="תשלום דחוי ל-15 לחודש"
                                            name="schedule"
                                            checked={values.schedule === 'deferred'}
                                            disabled={readOnly || !enableDeferredOption}
                                        />
                                    </RadioGroup>
                                    <Tips tips={['ניתן לבחור תשלום דחוי רק בתחילת החודש.']} />
                                </AppFormSection>
                            </AppFormContainer>
                        </Form>
                    )
                }}
            </Formik>
        </Page>
    )

    return page
}

export default CheckUploader
