/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { Fragment } from 'react'

// prop-types is a library for typechecking of props
import PropTypes from 'prop-types'

// @mui material components
import {
    Card,
    CardActions,
    CardActionArea,
    Icon,
    Button,
} from '@mui/material'

import { Link } from 'react-router-dom'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React base styles
import typography from 'assets/theme/base/typography'
import StatusBadge from 'components/StatusBadge'

function DocumentCard({
    image,
    title,
    info,
    onClick,
    className,
    action = false,
    status,
    warning,
    mode,
    route,
    onDelete,
}) {
    const labels = []
    const values = []

    // Convert this form `objectKey` of the object key in to this `object key`
    Object.keys(info).forEach((el) => labels.push(el))

    // Push the object values into the values array
    Object.values(info).forEach((el) => values.push(el))

    // Render the card info items
    const renderItems = labels.map((label, key) => (
        <MDBox key={label} display="flex" py={0.5}>
            <MDTypography
                variant="button"
                fontWeight="bold"
                textTransform="capitalize"

            >
                {label}:
            </MDTypography>
            <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                px={1}
            >
                {values[key] || ''}
            </MDTypography>
        </MDBox>
    ))

    const renderCardImage = () => (
        <MDBox
            position="relative"
            borderRadius="lg"
            pt={3}
            mx={2}
            sx={{ transition: 'transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)' }}
        >
            <MDBox
                component="img"
                src={image}
                alt={title}
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="relative"
                zIndex={1}
            />
            <MDBox
                borderRadius="lg"
                shadow="md"
                width="100%"
                height="100%"
                position="absolute"
                left={0}
                top="0"
                sx={{
                    backgroundImage: `url(${image})`,
                    transform: 'scale(0.94)',
                    filter: 'blur(12px)',
                    backgroundSize: 'cover',
                }}
            />
        </MDBox>
    )

    const blockOverrides = {
        mt: 1.5,
        mb: 1,
        px: 1,
        borderRadius: 2,
    }

    // const CardStatus = (status, warning) => (
    //   <>
    //     {status === 'valid'
    //       && <MDTypography variant="body2" bgColor="success" sx={blockOverrides}>
    //         תקין
    //       </MDTypography>}
    //       {warning
    //         && <MDTypography variant="body2" bgColor="warning" sx={blockOverrides}>
    //           {warning}
    //       </MDTypography>}
    //   </>
    // )

    const renderCardContent = () => (
        <>
            <MDBox pt={3} px={3}>
                <MDBox display="flex" justifyContent="center" alignItems="center" mt={action ? -8 : -4.25}>
                    {action}
                </MDBox>
                <MDBox display="flex" alignItems="center" sx={{ mt: 4 }}>
                    <MDTypography variant="h3">
                        {title}
                    </MDTypography>
                    {status && (
                        <StatusBadge
                            status={status}
                            sx={{
                                marginInlineStart: 3,
                            }}
                        />
                    )}
                </MDBox>
                {image === '.'
                    && <MDTypography variant="body2" bgColor="info" sx={blockOverrides}>
                        'לא ניתן להצגה'
                    </MDTypography>}
                {/* {!image
          && <MDTypography variant="body2" bgColor="warning" sx={blockOverrides}>
            לא הועלו מסמכים
        </MDTypography>} */}
                {/* <CardStatus status={status} warning={warning} /> */}
                {/* {status === 'valid'
          && <MDTypography variant="body2" bgColor="success" sx={blockOverrides}>
            תקין
        </MDTypography>} */}
                {warning
                    && <MDTypography variant="body2" bgColor="warning" sx={blockOverrides}>
                        {warning}
                    </MDTypography>}
            </MDBox>
            <MDBox pb={3} px={3}>
                <MDBox>
                    {renderItems}
                </MDBox>
            </MDBox>
        </>
    )

    const renderCardBody = () => (
        <>
            {image && (renderCardImage())}
            {renderCardContent()}
        </>
    )

    return (
        <Card
            sx={{
                '&:hover .card-header': {
                    transform: action && 'translate3d(0, -50px, 0)',
                },
            }}
        >
            {mode === 'card'
                ? (
                    <CardActionArea
                        component={Link}
                        to={route}
                        sx={{
                            flexGrow: 1,
                        }}
                    >
                        {renderCardBody()}
                    </CardActionArea>
                )
                : renderCardBody()}
            {onDelete != null
                ? (
                    <CardActions
                        sx={{
                            justifyContent: 'flex-end',
                            paddingInline: 2,
                        }}
                    >
                        <Link component={Button} p={1} onClick={onDelete}><Icon>delete</Icon></Link>
                    </CardActions>
                )
                : null}
        </Card>
    )
}

export default DocumentCard

DocumentCard.propTypes = {
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    action: PropTypes.oneOfType([PropTypes.bool, PropTypes.node]),
}
