import { React } from 'react'
import moment from 'moment'

function getFormattedDateStringCell(dateString) {
    const date = moment(dateString)
    const formattedDate = date.format('DD/MM/YY HH:mm:ss')
    const content = (
        <div
            style={{
                direction: 'ltr',
            }}
        >{formattedDate}</div>
    )
    return content
}

export function createCustomCellWithFormattedDate(field) {
    const format = field.meta?.format
    const customCell =
        format === 'date-string'
            ? {
                sortDescFirst: true,
                Cell: (cell) => getFormattedDateStringCell(cell.value),
            } : null
    return customCell
}
