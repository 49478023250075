import React, { useContext, useEffect, useState } from 'react'
import FormInput from '../../../components/form-input/form-input.component'
// import './sign-in.styles.scss'

import AuthContext from '../../../units/auth/AuthContext'
import FetchContext from '../../../context/fetch/FetchContext'

/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// react-router-dom components
import { Link, useLocation, useNavigate } from 'react-router-dom'

// @mui material components
import Switch from '@mui/material/Switch'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDInput from 'components/MDInput'
import MDButton from 'components/MDButton'
import { useNotificationContext } from 'units/notifications/NotificationContext'

// Authentication layout components
import IllustrationLayout from 'layouts/IllustrationLayout'

// Image
import bgImage from 'assets/images/illustrations/illustration-reset.jpg'

export default function SignIn() {
    const [loginIdentifier, setLoginIdentifier] = useState('')
    const [loginPassword, setLoginPassword] = useState('')
    const [busy, setBusy] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)

    const { addNotification } = useNotificationContext()

    const location = useLocation()
    const from = location.state?.from || '/'

    const navigate = useNavigate()

    const { authenticated } = useContext(AuthContext)
    const fetcher = useContext(FetchContext)

    useEffect(() => {
        if (authenticated) {
            navigate(from)
            setBusy(false)
        }
    }, [authenticated])

    const handleSetRememberMe = () => setRememberMe(!rememberMe)

    const handleSubmit = async (e) => {
        e.preventDefault()

        const postData = {
            identifier: loginIdentifier,
            password: loginPassword,
        }

        if (!busy) {
            // sending a HTTP post request to the strapi api to authenticate.
            setBusy(true)

            try {
                await fetcher.authenticate(postData)
            }
            catch (error) {
                console.log('error', error)

                addNotification({
                    type: 'error',
                    content: 'נא לבדוק שהפרטים שהזנת נכונים',
                })

                setBusy(false)
            }
        }
    }

    return (
        <IllustrationLayout
            title="ברוכים הבאים"
            description="נא להזין שם משתמש או כתובת מייל וסיסמה"
            illustration={bgImage}
        >
            <MDBox
                component="form"
                role="form"
                onSubmit={(e) => handleSubmit(e)}
            >
                <MDBox mb={2}>
                    <MDInput
                        type="text"
                        label="שם משתמש או כתובת מייל"
                        fullWidth
                        onChange={(e) => setLoginIdentifier(e.target.value)}
                    />
                    {/* <FormInput
                          name="identifier"
                          type="text"
                          placeholder="הקישו כתובת מייל להתחברות"
                          onChange={(e) => setLoginIdentifier(e.target.value)}
                          required
                      /> */}
                </MDBox>
                <MDBox mb={2}>
                    <MDInput
                        type="password"
                        label="סיסמה"
                        fullWidth
                        onChange={(e) => setLoginPassword(e.target.value)}
                    />
                    {/* <FormInput
                        name="password"
                        type="password"
                        onChange={(e) => setLoginPassword(e.target.value)}
                        placeholder="סיסמה"
                        required
                      /> */}
                </MDBox>
                {/* <MDBox display="flex" alignItems="center" ml={-1}>
                  <Switch checked={rememberMe} onChange={handleSetRememberMe} />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    onClick={handleSetRememberMe}
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;Remember me
                  </MDTypography>
                </MDBox> */}
                <MDBox mt={4} mb={1}>
                    <MDButton
                        variant="gradient"
                        color="info"
                        size="large"
                        fullWidth
                        type="submit"
                        disabled={busy}
                    >
                        {busy ? 'נא להמתין' : 'התחברות'}
                    </MDButton>
                    {/* <button
                    className="btn btn-primary d-block btn-user w-100"
                    type="submit"
                  >התחברות</button> */}
                </MDBox>
                {/* <MDBox mt={3} textAlign="center">
                  <MDTypography variant="button" color="text">
                      אין לך חשבון?{' '}
                      <MDTypography
                        component={Link}
                        to="/authentication/sign-up/cover"
                        variant="button"
                        color="info"
                        fontWeight="medium"
                        textGradient
                      >
                        הרשמה
                      </MDTypography>
                    </MDTypography>
                </MDBox> */}
            </MDBox>

            {/*
        <div className="body">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-9 col-lg-12 col-xl-10">
                        <div className="card shadow-lg o-hidden border-0 my-5">
                            <div className="card-body p-0">
                                <div className="row">
                                    <div className="col-lg-6 d-none d-lg-flex">
                                        <div className="flex-grow-1 bg-login-image picture" />
                                    </div>
                                    <div className="col-lg-6">
                                        <div className="p-5">
                                            <div className="text-center">
                                                <h4 className="text-dark mb-4">ברוכים הבאים</h4>
                                            </div>
                                            <form className="user" onSubmit={sendAuthenticationCall}>
                                                <div className="mb-3">
                                                    <FormInput
                                                        name="identifier"
                                                        type="text"
                                                        placeholder="הקישו כתובת מייל להתחברות"
                                                        onChange={(e) => setLoginIdentifier(e.target.value)}
                                                        required
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <FormInput
                                                        name="password"
                                                        type="password"
                                                        onChange={(e) => setLoginPassword(e.target.value)}
                                                        placeholder="סיסמה"
                                                        required
                                                    />
                                                </div>
                                                <button
                                                    className="btn btn-primary d-block btn-user w-100"
                                                    type="submit"
                                                >התחברות</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div> */}
        </IllustrationLayout>
    )
}

// export default Illustration

// class SignIn extends React.Component {
//     // constructor(props) {
//     //     super(props)
//     //     this.state = {
//     //         LoginIdentifier: '',
//     //         LoginPassword: '',
//     //         ResponseBack: false,
//     //     }
//     // }

//     // sendAuthenticationCall = async (e) => {
//     //     // will send an authentication call to the api and return the token and save it into localStorage

//     //     e.preventDefault()

//     //     let postData = {
//     //         "identifier": this.state.LoginIdentifier,
//     //         "password": this.state.LoginPassword
//     //     }

//     //     if (this.state.ResponseBack === false) {
//     //         // sending a HTTP post request to the strapi api to authenticate.
//     //         this.setState({ ResponseBack: true })

//     //         try {
//     //             const jwt = await fetch({
//     //                 method: 'post',
//     //                 path: `/auth/local`,
//     //                 data: postData,
//     //                 responseHandler: (response) => response.data.jwt,
//     //                 rethrow: true,
//     //                 authorize: false,
//     //             })

//     //             storeAuthenticationToken(jwt)

//     //             let userInfo = await fetchUser()

//     //             localStorage.setItem('user', JSON.stringify(userInfo))

//     //             // redirect to next page
//     //             RedirectToURL("/companies", this.props)
//     //         }
//     //         catch(err) {
//     //             this.setState({ ResponseBack: false })
//     //         }
//     //     }
//     // }

//     render() {

//         return (
//             <div className="body">
//                 <div className="container">
//                     <div className="row justify-content-center">
//                         <div className="col-md-9 col-lg-12 col-xl-10">
//                             <div className="card shadow-lg o-hidden border-0 my-5">
//                                 <div className="card-body p-0">
//                                     <div className="row">
//                                         <div className="col-lg-6 d-none d-lg-flex">
//                                             <div className="flex-grow-1 bg-login-image picture" />
//                                         </div>
//                                         <div className="col-lg-6">
//                                             <div className="p-5">
//                                                 <div className="text-center">
//                                                     <h4 className="text-dark mb-4">ברוכים הבאים</h4>
//                                                 </div>
//                                                 <form className="user" onSubmit={this.sendAuthenticationCall}>
//                                                     <div className="mb-3">
//                                                         <FormInput
//                                                             name="identifier"
//                                                             type="text"
//                                                             placeholder="הקישו כתובת מייל להתחברות"
//                                                             onChange={(e) => this.setState({ LoginIdentifier: e.target.value })}
//                                                             required
//                                                         />
//                                                     </div>
//                                                     <div className="mb-3">
//                                                         <FormInput
//                                                             name="password"
//                                                             type="password"
//                                                             onChange={(e) => this.setState({ LoginPassword: e.target.value })}
//                                                             placeholder="סיסמה"
//                                                             required
//                                                         />
//                                                     </div>
//                                                     <button
//                                                         className="btn btn-primary d-block btn-user w-100"
//                                                         type="submit"
//                                                     >התחברות</button>
//                                                 </form>
//                                             </div>
//                                         </div>
//                                     </div>
//                                 </div>
//                             </div>
//                         </div>
//                     </div>
//                 </div>
//             </div>
//         )
//     }
// }

// export default SignIn
