import moment from 'moment'

const checkObject = {
    bank: {
        Header: 'בנק',
        accessor: 'bank',
        type: 'string',
    },
    branch: {
        Header: 'סניף הבנק',
        accessor: 'branch',
        type: 'string',
    },
    account: {
        Header: 'מספר חשבון הבנק',
        accessor: 'account',
        type: 'string',
    },
    number: {
        Header: 'מספר שיק',
        accessor: 'number',
        type: 'string',
    },
    createdAt: {
        Header: 'נוצר בתאריך',
        accessor: 'createdAt',
        type: 'date',
        custom: check => moment(check.createdAt).format('D/M/yy'),
    },
    updatedAt: {
        Header: 'עודכן בתאריך',
        accessor: 'updatedAt',
        type: 'date',
        custom: check => moment(check.updatedAt).format('D/M/yy'),
    },
}

const checkObjectShallowCopy = { ...checkObject }
export default checkObjectShallowCopy
