export class ElectronPlatform {
    get name() {
        return 'electron'
    }

    get router() {
        return 'hash'
    }

    get env() {
        return process.env
    }

    get isDesktopApp() {
        return true
    }

    async getAppVersion() {
        // const { app } = await import('electron')
        // const version = app.getVersion()
        // return version
        const { version } = await import('../../../package.json')
        return version
    }

    async checkForUpdates() {
        return window.electron.versioning.checkForUpdates()
    }

    readFileSync(path) {
        const file = window.electron.fs.readFileSync(path)
        console.log('electron read file', file)
        return file
    }

    existsSync(path) {
        return Boolean(window.electron.fs.existsSync(path))
    }

    showMessageBoxSync(options) {
        const { dialog } = window.electron
        dialog.showMessageBoxSync(null, options)
    }

    onMessage(callback) {
        window.electron.messaging.onMessage(callback)
    }
}
