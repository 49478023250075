import { useContext, useEffect } from 'react'
import FetchContext from '../../context/fetch/FetchContext'
import { activateScanner } from './activateScanner'
import { ScannerParser } from './ScannerParser'
import PlatformContext from 'context/platform/PlatformContext'


// const insertScannedData = (rawScannerText) => {
//   const scannerTextLines = rawScannerText.split('\n')
//   if (!scannerTextLines || !scannerTextLines[1] || (Array.isArray(scannerTextLines) && scannerTextLines.length < 2)) {
//     platform.showMessageBoxSync({ type: 'error', buttons: [], title: 'בעיה בסורק', message: 'הסורק לא מצא מסמך בתא סריקה, אנא נסה שנית.' })
//     return
//   }
//   const dataObject = JSON.parse(scannerTextLines[1])
//   const fieldsData = translateLinereadToObject(dataObject.lineRead)
//   const imagesObject = {}
//   let imageExistError = false
//   Object.entries(dataObject).forEach((dataField, index) => {
//     if (index === 0) return
//     const fullPath = `${baseLocation}${dataField[1]}`
//     const fileExists = platform.existsSync(fullPath) ? true : false
//     if (!fileExists) imageExistError = true

//     const imageObject = {
//       name: voucherNamesArray[index - 1],
//       url: fileExists ? fullPath : null,
//       imageType: fullPath ? fullPath.split('.')[fullPath.split('.').length - 1] : null,
//     }
//     imagesObject[voucherTypesArray[index - 1]] = imageObject
//   })
//   setFormData({
//     ...formData,
//     asmachtaA: fieldsData.asmachtaA,
//     beneficiaryCode: fieldsData.beneficiaryCode,
//     asmachtaB: fieldsData.asmachtaB,
//     images: imagesObject,
//   })
//   setPayload({
//     ...payload,
//     asmachtaA: fieldsData.asmachtaA,
//     beneficiaryCode: fieldsData.beneficiaryCode,
//     asmachtaB: fieldsData.asmachtaB,
//     images: imagesObject,
//   })
//   if (imageExistError) {
//     platform.showMessageBoxSync({ type: 'warning', buttons: [], title: 'קבצים לא התקבלו', message: 'אחד(או יותר) מהקבצים שהסורק ייצר לא נמצאו, יש לחפש ולהעלות ידנית.' })
//   }
// }

function useScanner({ scannerTrigger, onSuccess, onError, onDone }) {
    const platform = useContext(PlatformContext)
    const fetcher = useContext(FetchContext)

    useEffect(() => {
        if (scannerTrigger) {
            // const processParsedData = ({ fields, images }) => {
            //   const imagesObject = {}
            //   let missingImages = false

            //   // Object.entries(fields)
            //   //   .reduce(([key, fileName], index) => {
            //   //     return {

            //   //     }
            //   //   })
            //   //   .forEach((dataField, index) => {
            //   //     const fileName = dataField[1]
            //   //     const fullPath = `${baseLocation}${fileName}`
            //   //     const fileExists = this.platform.existsSync(fullPath) ? true : false
            //   //     if (!fileExists) {
            //   //       missingImages = true
            //   //     }

            //   //     const imageObject = {
            //   //       name: checkNamesArray[index - 1],
            //   //       url: fileExists ? fullPath : null,
            //   //       imageType: fullPath ? fullPath.split('.')[fullPath.split('.').length - 1] : null,
            //   //     }
            //   //     imagesObject[checkTypesArray[index - 1]] = imageObject
            //   //   })

            //   Object.entries(images).forEach(([key, fileName], index) => {
            //     const fullPath = `${baseLocation}${fileName}`
            //     const fileExists = platform.existsSync(fullPath)
            //     if (!fileExists) {
            //       missingImages = true
            //     }

            //     const pathParts = fullPath.split('.')
            //     const imageObject = {
            //       name: key, // checkNamesArray[index - 1],
            //       url: fileExists ? fullPath : null,
            //       imageType: fullPath ? pathParts[pathParts.length - 1] : null,
            //     }
            //     imagesObject[checkTypesArray[index - 1]] = imageObject
            //   })

            //   return {
            //     fieldsData: fields,
            //     imagesObject,
            //     missingImages,
            //   }
            // }

            const doActivateScanner = async () => {
                let scanResult
                let scanError
                try {
                    const scannedData = await activateScanner(fetcher, scannerTrigger)
                    console.log('scannedData', scannedData)
                    if (scannedData) {
                        const scannerParser = new ScannerParser(platform)

                        scanResult = scannerParser.parseScannerText(scannedData)
                        console.log('scanner parsed data', scanResult)

                        // scanResult = processParsedData(parsedData)
                        // console.log('scanner prcessed data', scanResult)

                        if (onSuccess) {
                            onSuccess(scanResult)
                        }
                    }
                }
                catch (error) {
                    console.error('scan error', error)
                    scanError = error
                    if (onError) {
                        onError(error)
                    }
                }
                finally {
                    if (onDone) {
                        onDone(scanResult, scanError)
                    }
                }
            }

            const doFakeScanner = async () => {
                let imagesObject
                try {
                    const baseLocation = '/Users/noam/dev/zone/customers/vipost/vipost-app/Tests/check_1/'
                    imagesObject = {
                        Check_Front_tif: {
                            imageType: 'tif',
                            name: 'F1',
                            url: `${baseLocation}444-333-22062021021732784-F1.tif`,
                        },
                        Check_Back_tif: {
                            imageType: 'tif',
                            name: 'R1',
                            url: `${baseLocation}444-333-22062021021732784-R1.tif`,
                        },
                        Check_Front_jpg: {
                            imageType: 'jpg',
                            name: 'F2',
                            url: `${baseLocation}444-333-22062021021732784-F2.jpg`,
                        },
                        Check_Back_jpg: {
                            imageType: 'jpg',
                            name: 'R2',
                            url: `${baseLocation}444-333-22062021021732784-R2.jpg`,
                        },
                    }

                    if (onSuccess) {
                        onSuccess({ imagesObject })
                    }
                }
                catch (error) {
                    if (onError) {
                        onError(error)
                    }
                }
                finally {
                    if (onDone) {
                        onDone({ imagesObject })
                    }
                }
            }

            if (scannerTrigger?.toString().includes('fakemac')) {
                doFakeScanner()
            }
            else {
                doActivateScanner()
            }
        }
    }, [scannerTrigger])
}

export default useScanner
