import moment from 'moment'
import vouchersPathLabels from './vouchersPathLabels'

const voucherObject = {
    barcode: {
        Header: 'ברקוד',
        accessor: 'barcode',
        type: 'string',
    },
    voucherType: {
        Header: 'מסלול',
        type: 'string',
        custom: voucher => {
            return vouchersPathLabels(voucher)
        },
    },
    createdAt: {
        Header: 'נוצר בתאריך',
        accessor: 'createdAt',
        type: 'date',
        custom: voucher => moment(voucher.createdAt).format('D/M/yy'),
    },
    updatedAt: {
        Header: 'עודכן בתאריך',
        accessor: 'updatedAt',
        type: 'date',
        custom: voucher => moment(voucher.updatedAt).format('D/M/yy'),
    },
}

const voucherObjectShallowCopy = { ...voucherObject }
export default voucherObjectShallowCopy
