import { useEffect, useState } from 'react'
import { jwtDecode } from 'jwt-decode'
import AuthContext from '../../units/auth/AuthContext'
import AuthStorage from '../../units/auth/AuthStorage'

export default function AuthRoot({ children }) {
    const authStorage = new AuthStorage()

    const [authenticated, setAuthenticated] = useState(null)
    const [token, setToken] = useState(getAuthenticationToken())
    const [user, setUser] = useState(null)

    function getAuthenticationToken() {
        const token = authStorage.getAuthenticationToken() || null
        return token
    }

    useEffect(() => {
        const token = getAuthenticationToken()
        setToken(token)

        const user = authStorage.getAuthenticatedUser() || null
        setUser(user)
    }, [])

    useEffect(() => {
        let isAuthenticated = false
        try {
            if (token != null) {
                const decodedToken = jwtDecode(token)
                const tokenExpiration = decodedToken.exp
                const isValidToken = Date.now() < tokenExpiration * 1000
                isAuthenticated = isValidToken
            }
        }
        catch (error) {
            console.error(error)
            isAuthenticated = false
        }
        finally {
            setAuthenticated(isAuthenticated)
        }
    }, [token])

    useEffect(() => {
        if (authenticated === false) {
            authStorage.removeAuthentication()
        }
    }, [authenticated])

    async function updateAuthentication(token, user) {
        authStorage.storeAuthentication(token, user)
        setToken(token)
        setUser(user)
    }

    function logOut() {
        setAuthenticated(false)
    }

    return (
        <AuthContext.Provider value={{
            authenticated,
            token,
            user,
            updateAuthentication,
            logOut,
        }}>
            {children}
        </AuthContext.Provider>
    )
}
