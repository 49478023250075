import React, { StrictMode } from 'react'
import { createRoot } from 'react-dom/client'
import { initErrorReporter } from 'units/logging/errors'
import AppContainer from './AppContainer'

initErrorReporter()

const rootNode = document.getElementById('root')
const root = createRoot(rootNode)

root.render(
    <StrictMode>
        <AppContainer />
    </StrictMode>,
)
