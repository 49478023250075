import { React } from 'react'
import DataTable from '../../examples/Tables/DataTable'
import { createCustomCellWithOptions } from './cellCreators/createCustomCellWithOptions'
import { createCustomCellWithFormattedDate } from './cellCreators/createCustomCellWithFormattedDate'
import { createCustomCellWithStatusBadge } from './cellCreators/createCustomCellWithStatusBadge'

function getCustomCell(field) {
    const customCellCreators = [
        createCustomCellWithOptions,
        createCustomCellWithFormattedDate,
        createCustomCellWithStatusBadge,
    ]

    const customCell = customCellCreators.reduce(
        (memo, creator) => {
            if (memo != null) {
                return memo
            }
            else {
                return creator(field)
            }
        },
        null,
    )
    return customCell
}

function convertSchemaFieldsToDataTableFields(fieldIds, fields, sortFieldId) {
    const columns = fieldIds.map(
        (fieldId) => {
            const field = fields[fieldId]

            const isSortedOption =
                fieldId === sortFieldId
                    ? {
                        isSorted: true,
                    }
                    : null

            const customCell = getCustomCell(field)

            return {
                Header: field.label,
                accessor: fieldId,
                ...isSortedOption,
                ...customCell,
            }
        },
    )
    return columns
}

export default function SchemaTable({
    schema,
    data,
    fieldIds,
    sortFieldId,
    columns = [],
    canSearch,
    onRowClick,
}) {
    const schemaDescription = schema.describe()
    const { fields } = schemaDescription

    const schemaColumns = convertSchemaFieldsToDataTableFields(fieldIds, fields, sortFieldId)

    return (
        <DataTable
            table={{
                columns: [...schemaColumns, ...columns],
                rows: data,
            }}
            canSearch={canSearch}
            onRowClick={onRowClick}
        />
    )
}
