// const checkTypesArray = [
//   'Check_Front_tif',
//   'Check_Front_jpg',
//   'Check_Back_tif',
//   'Check_Back_jpg',
// ]

// const checkNamesArray = [
//   'Check Front tif',
//   'Check Front jpg',
//   'Check Back tif',
//   'Check Back jpg',
// ]

export class ScannerParser {
    constructor(platform) {
        this.platform = platform
    }

    translateLinereadToItems(lineRead) {
        const regex = /([a-z]\d{3}[a-z])/g
        const newText = lineRead.replace(regex, '')
        const stringArray = newText.split('')
        let oneLineDigits = ''
        for (let i = 0; i < stringArray.length; i++) {
            if (stringArray[i] === '_') {
                oneLineDigits += stringArray[i]
            }
            else if (isNaN(Number(stringArray[i])) && (!isNaN(Number(stringArray[i - 1])) || stringArray[i - 1] === '_')) {
                oneLineDigits += ' '
            }
            else if (!isNaN(Number(stringArray[i]))) {
                oneLineDigits += stringArray[i]
            }
        }
        const numbersArray = oneLineDigits.split(' ')
        return numbersArray
        // const result = this.map.reduce((result, [key, index]) => {
        //   return {
        //     ...result,
        //     [key]: numbersArray[index],
        //   }
        // }, {})
        // return result
        // return {
        //   number: numbersArray[0],
        //   bank: numbersArray[1],
        //   branch: numbersArray[2].slice(0, 3),
        //   account: numbersArray[3],
        // }
    }

    parseScannerText(rawScannerText) {
        console.log('raw scanner text:', rawScannerText)
        const scannerTextLines = rawScannerText.split('\n')
        if (!scannerTextLines || !scannerTextLines[1] || (Array.isArray(scannerTextLines) && scannerTextLines.length < 2)) {
            throw new ScannerParserError({
                title: 'בעיה בסורק',
                message: 'הסורק לא מצא מסמך בתא סריקה. נא לנסות שוב.',
            })
        }

        const dataObject = JSON.parse(scannerTextLines[1])
        const { lineRead, ...images } = dataObject
        const items = this.translateLinereadToItems(lineRead)

        return { items, images }

        // const imagesObject = {}
        // let missingImages = false

        // Object.entries(dataObject)
        //   .slice(1)
        //   .reduce(([key, fileName], index) => {
        //     return {

        //     }
        //   })
        //   .forEach((dataField, index) => {
        //     const fileName = dataField[1]
        //     const fullPath = `${baseLocation}${fileName}`
        //     const fileExists = this.platform.existsSync(fullPath) ? true : false
        //     if (!fileExists) {
        //       missingImages = true
        //     }

        //     const imageObject = {
        //       name: checkNamesArray[index - 1],
        //       url: fileExists ? fullPath : null,
        //       imageType: fullPath ? fullPath.split('.')[fullPath.split('.').length - 1] : null,
        //     }
        //     imagesObject[checkTypesArray[index - 1]] = imageObject
        //   })

        // Object.entries(dataObject).forEach((dataField, index) => {
        //   if (index === 0) return
        //   const fullPath = `${baseLocation}${dataField[1]}`
        //   const fileExists = this.platform.existsSync(fullPath) ? true : false
        //   if (!fileExists) {
        //     missingImages = true
        //   }

        //   const imageObject = {
        //     name: checkNamesArray[index - 1],
        //     url: fileExists ? fullPath : null,
        //     imageType: fullPath ? fullPath.split('.')[fullPath.split('.').length - 1] : null,
        //   }
        //   imagesObject[checkTypesArray[index - 1]] = imageObject
        // })

        // return {
        //   fieldsData: fields,
        //   imagesObject,
        //   missingImages,
        // }
    }
}

export class ScannerParserError extends Error {
    constructor(options) {
        super(options.message)
        this.name = this.constructor.name
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor)
        }
        else {
            this.stack = (new Error(options.message)).stack
        }

        this.title = options.title
    }
}
