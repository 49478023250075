import { useContext } from 'react'
import AuthContext from 'units/auth/AuthContext'

export default function useQueryLibrary() {
    const authenticator = useContext(AuthContext)

    function authenticatedQuery(query) {
        const user = authenticator.user
        const userId = user?.id
        return [userId, ...query]
    }

    const library = {
        meQuery() {
            return authenticatedQuery(['user', 'me'])
        },

        companiesQuery(filter) {
            return authenticatedQuery(['companies', filter])
        },

        oneCompanyQuery(companyId) {
            return authenticatedQuery(['companies', companyId.toString()])
        },

        oneCompanyForEntriesPageQuery(companyId) {
            return authenticatedQuery(['entries', 'company', companyId.toString()])
        },

        entriesForEntriesPageQuery(companyId) {
            return authenticatedQuery(['entries', 'entries-page', companyId.toString()])
        },

        entriesForActivePageQuery() {
            return authenticatedQuery(['entries', 'active-page'])
        },

        oneEntryQuery(entryId) {
            return authenticatedQuery(['entries', entryId.toString()])
        },

        oneEntryForEntryPageQuery(entryId) {
            return authenticatedQuery(['entries', 'entry-page', entryId.toString()])
        },

        oneDocumentQuery(documentId) {
            return authenticatedQuery(['documents', documentId.toString()])
        },

        oneDocumentForDocumentPageQuery(documentId) {
            return authenticatedQuery(['documents', 'documents-page', documentId.toString()])
        },
    }

    return library
}
