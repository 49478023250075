import { React, useContext, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import useQueryLibrary from '../../hooks/useQueryLibrary'
import FetchContext from '../../context/fetch/FetchContext'
import Page from '../pages/Page'
import DatumLoader from '../loaders/DatumLoader'
import { useConfirm } from 'material-ui-confirm'
import { useNotificationContext } from '../../units/notifications/NotificationContext'
import EntriesTable from './EntriesTable'

export default function EntriesPage() {
    const { companyId } = useParams()

    const { addNotification } = useNotificationContext()

    const navigate = useNavigate()

    const queryLibrary = useQueryLibrary()

    const fetcher = useContext(FetchContext)

    const { data: user, isError: isUserError } = useQuery({
        queryKey: queryLibrary.meQuery(),
        queryFn: async () => {
            const data = await fetcher.fetchUser()
            return data
        },
    })

    const { data: company, isError: isCompanyError } = useQuery({
        queryKey: queryLibrary.oneCompanyForEntriesPageQuery(companyId),
        queryFn: async () => {
            const data = await fetcher.fetchMinimalCompanyInfo(companyId)
            return data
        },
    })

    // const [documentPendingDeleteConfirmation, setDocumentPendingDeleteConfirmation] = useState(null)
    // const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false)

    // const deleteDocumentWithConfirmation = async (documentId) => {
    //   // setDocumentPendingDeleteConfirmation(documentId)
    //   setDeleteConfirmationOpen(true)
    // }

    // const deleteDocument = async (documentId) => {
    //   // deleteEntryMutation.mutate(cell.value)
    //   // try {
    //   //   // setIsPopupLoading(true)

    //   // setDeleteConfirmationOpen(false)

    //   // deleteDocumentMutation.mutate(documentId)
    //   //   // await fetch({
    //   //   //   method: 'delete',
    //   //   //   path: `/documents/${documentId}`,
    //   //   // })

    //   //   // TODO invalidate documents
    //   //   // getDocuments()
    //   //   // setIsPopupLoading(false)
    //   //   // setPopup(null)
    //   // }
    //   // catch (err) {
    //   //   console.log(err)
    //   // }
    // }

    // const renderDeleteConfirmationDialog = () => {
    //   return (
    //     <Dialog
    //       color="warning"
    //       icon="delete"
    //       title="מחיקה"
    //       content="אי אפשר לשחזר את הנתונים אחרי המחיקה. האם בטוחים שאתם רוצים למחוק את המסמך?"
    //       open={deleteConfirmationOpen}
    //       // close={d}
    //         // () => setDeleteConfirmationOpen(false)}
    //       onClose={deleteDocument}
    //     >
    //       <DialogTitle>מחיקה</DialogTitle>
    //       <DialogContent>
    //         <DialogContentText>
    //           <MDBox>
    //             <MDTypography>אתם בטוחים שאתם רוצים למחוק את הרשומה?</MDTypography>
    //             <MDTypography>לא ניתן לשחזר את הנתונים.</MDTypography>
    //           </MDBox>
    //         </DialogContentText>
    //       </DialogContent>
    //       <DialogActions>
    //         <MDButton color="secondary">אישור</MDButton>
    //         <MDButton color="primary" autoFocus>ביטול</MDButton>
    //       </DialogActions>
    //     </Dialog>
    //   )
    // }

    const confirm = useConfirm()

    const [busyEntryIds, setBusyEntryIds] = useState([])

    const deleteEntryMutation = useMutation({
        mutationFn: (entryId) => {
            console.log('mutating')
            // setBusy(true)

            setBusyEntryIds([...busyEntryIds, entryId])

            return fetcher.authenticatedFetch({
                method: 'delete',
                path: `/entries/${entryId}`,
                responseHandler: (response) => response,
            })
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                queryLibrary.oneCompanyQuery(companyId),
            )
            await queryClient.invalidateQueries(
                queryLibrary.entriesForEntriesPageQuery(companyId),
            )

            addNotification({
                type: 'success',
                content: 'הרשומה נמחקה בהצלחה',
            })
        },
        onError: (error) => {
            addNotification({
                type: 'error',
                content: error?.message,
            })
        },
        onSettled: (data) => {
            const entryId = data?.data?.data?.id
            setBusyEntryIds([...busyEntryIds.filter((busyEntryId) => busyEntryId !== entryId)])
        },
    })

    const deleteEntry = async (entryId) => {
        try {
            await confirm({
                title: `האם למחוק את רשומה ${entryId}? `,
                description: 'שימו לב! אי אפשר לשחזר את הנתונים אחרי המחיקה.',
            })
            deleteEntryMutation.mutate(entryId)
        }
        catch (error) {
            if (error) {
                addNotification({
                    type: 'error',
                    content: error?.message,
                })
            }
        }
    }

    const queryClient = useQueryClient()

    const { data: entries, isLoading } = useQuery({
        queryKey: queryLibrary.entriesForEntriesPageQuery(companyId),
        queryFn: async () => {
            const entries = await fetcher.fetchEntriesForEntriesPage(company)
            return entries
        },
        enabled: !!company,
    })

    const [busy, setBusy] = useState(false)

    function navigateToEntry(companyId, entryId) {
        navigate(`/companies/${companyId}/entries/${entryId}`)
    }

    const createEntryMutation = useMutation({
        mutationFn: (newEntry) => {
            console.log('mutating')
            setBusy(true)
            return fetcher.authenticatedFetch({
                method: 'post',
                path: `/entries`,
                headers: {
                    'Content-Type': 'application/json',
                },
                data: { data: newEntry },
                responseHandler: (response) => response.data.data.id,
            })
            // return createEntry(newEntry)
        },
        onSuccess: async (data, variables, context) => {
            console.log('success', data, variables, context)

            const entryId = data

            await queryClient.invalidateQueries(
                queryLibrary.oneCompanyQuery(companyId),
            )
            await queryClient.invalidateQueries(
                queryLibrary.entriesForEntriesPageQuery(companyId),
            )

            navigateToEntry(companyId, entryId)
        },
        onSettled: (data) => {
            console.log('mutation settled')

            setBusy(false)
        },
    })

    const createEntry = async (event) => {

        event.preventDefault()

        const newEntry = {
            company: [
                company.id,
            ],
            user: [
                user.id,
            ],
        }

        createEntryMutation.mutate(newEntry)
    }

    const commands = [
        {
            to: `/companies/${companyId}/entries/`,
            label: 'רשומה חדשה',
            onClick: (e) => createEntry(e),
            disabled: busy || isUserError || isCompanyError,
        },
        {
            scheme: 'link',
            to: `/companies/${companyId}`,
            label: 'לחברה',
            position: 'end',
            color: 'info',
            disabled: busy,
        },
        {
            scheme: 'link',
            icon: 'arrow_upward',
            to: `/companies`,
            position: 'end',
            color: 'info',
            disabled: busy,
        },
    ]

    return (
        <Page
            commands={commands}
            title={company?.companyName || <DatumLoader />}
            busy={busy || isLoading}
        >
            <EntriesTable
                entries={entries}
                busyEntryIds={busyEntryIds}
                deleteEntry={deleteEntry}
                navigateToEntry={(entryId) => {
                    navigateToEntry(companyId, entryId)
                }}
            />
        </Page>
    )
}
