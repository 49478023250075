export function getDocumentKind(document) {
    if (document == null) {
        return null
    }

    const { kind, type } = document

    if (kind != null) {
        return kind
    }

    if (type == null) {
        return null
    }
    else if (type === 'check') {
        return 'payment'
    }
    else if (type.startsWith('voucher')) {
        return 'voucher'
    }

    return type
}
