import React, { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Formik, Form } from 'formik'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import AuthContext from 'units/auth/AuthContext'
import useQueryLibrary from 'hooks/useQueryLibrary'
import FetchContext from '../../context/fetch/FetchContext'
import Page from '../../components/pages/Page'
import AppFormContainer from 'components/forms/AppFormContainer'
import AppFormSection from 'components/forms/AppFormSection'
import schema from 'schemas/company'
import { useNotificationContext } from 'units/notifications/NotificationContext'

const MODE_CREATE = 'create'
const MODE_EDIT = 'edit'

const CompanyForm = () => {
    const navigate = useNavigate()

    const [busy, setBusy] = useState(false)

    const { addNotification } = useNotificationContext()

    const queryLibrary = useQueryLibrary()

    const { companyId } = useParams()

    const mode = useMemo(() => companyId === 'add' ? MODE_CREATE : MODE_EDIT, [companyId])

    const queryClient = useQueryClient()

    const fetcher = useContext(FetchContext)

    const schemaDescription = schema.describe()
    const { fields } = schemaDescription

    const mutation = useMutation({
        mutationFn: (newCompany) => {
            console.log('mutating')
            setBusy(true)
            return postCompany(newCompany)
        },
        onSuccess: async (data, variables, context) => {
            console.log('success', data, variables, context)

            if (mode === MODE_CREATE) {
                navigate(`/companies/${data.id}/entries`)
            }
            else if (mode === MODE_EDIT) {
                await queryClient.invalidateQueries({
                    queryKey: queryLibrary.oneCompanyQuery(companyId),
                })
            }

            addNotification({
                type: 'success',
                content: 'החברה נשמרה בהצלחה',
            })
        },
        onError: (error) => {
            addNotification({
                type: 'error',
                content: error?.message,
            })
        },
        onSettled: (data) => {
            console.log('mutation settled')

            setBusy(false)
        },
    })

    const emptyCompany = {
        companyId: '',
        companyName: '',
        city: '',
        street: '',
        addressNumber: '',
        poBox: '',
        companyType: '',
        reportingPeriodType: '',
    }

    let company

    const {
        data,
        isError,
        isInitialLoading,
    } = useQuery({
        queryKey: queryLibrary.oneCompanyQuery(companyId),
        queryFn: async () => {
            const data = await fetcher.fetchOneCompany(companyId)
            return data
        },
        enabled: mode === MODE_EDIT && fetcher.isAuthenticated(),
    })

    if (mode === MODE_CREATE) {
        company = useMemo(() => {
            return { ...emptyCompany }
        })
    }
    else if (mode === MODE_EDIT) {
        company = { ...data }

        Object.entries(fields).forEach(([key, field], index) => {
            if (company[key] == null) {
                company[key] = emptyCompany[key]
            }
        })
    }

    // const company = useMemo(() => {
    //   if (mode === MODE_CREATE) {
    //     return {
    //       companyId: '',
    //       companyName: '',
    //       city: '',
    //       street: '',
    //       addressNumber: '',
    //       poBox: '',
    //       companyType: '',
    //       reportingPeriodType: '',
    //       // users: [userId],
    //     }
    //   }
    //   else {
    //     const { data } = useQuery({
    //       queryKey: ['companies', companyId ],
    //       queryFn: async () => {
    //         const data = await fetchOneCompany(companyId)
    //         return data
    //       },
    //     })
    //     return data
    //   }
    // })

    // setCompany(initialCompany)

    const authenticator = useContext(AuthContext)

    const postCompany = async (newCompany) => {
        // try {
        if (mode === MODE_CREATE) {
            const { user } = authenticator
            const userId = user?.id

            const result = await fetcher.authenticatedFetch({
                method: 'post',
                path: `/companies`,
                data: {
                    data: {
                        ...newCompany,
                        users: [userId],
                    },
                },
                // responseHandler: (response) => response,
            })
            return result
        }
        else if (mode === MODE_EDIT) {
            const result = await fetcher.authenticatedFetch({
                method: 'put',
                path: `/companies/${companyId}`,
                data: {
                    data: {
                        ...newCompany,
                        // users: [userId],
                    },
                },
                // responseHandler: (response) => response,
            })
            return result
        }

        // navigate('/companies')
        // }
        // catch (err) {
        //   throw new Error()
        //   console.log(err)
        // }
    }

    const handleSubmit = (values) => {
        console.log('submitting', values)
        // setCompany(values)

        mutation.mutate(values)
    }

    const commands = [
        {
            label: 'שמירה',
            type: 'submit',
            position: 'start',
            color: 'primary',
            form: 'form',
            disabled: busy || isError || isInitialLoading,
        },
        {
            scheme: 'link',
            label: 'רשומות',
            to: `entries`,
            position: 'end',
            color: 'info',
            disabled: mode === MODE_CREATE,
        },
        {
            scheme: 'link',
            icon: 'arrow_upward',
            to: `/companies`,
            position: 'end',
            color: 'info',
            disabled: busy,
        },
    ]

    const page = (
        <Page
            title={mode === MODE_CREATE ? 'הוספת חברה חדשה' : company?.companyName ?? 'ללא שם'}
            commands={commands}
            busy={busy || isInitialLoading}
        >
            <Formik
                onSubmit={async (values, action) => {
                    await handleSubmit(values)
                }}
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={company}
            >
                <Form id="form">
                    <AppFormContainer>
                        <AppFormSection
                            title="פרטים בסיסיים"
                            section="basic"
                            fields={fields}
                            xs={12}
                            md={6}
                            lg={4}
                        />
                        <AppFormSection
                            title="כתובת"
                            section="address"
                            fields={fields}
                            xs={12}
                            md={6}
                            lg={4}
                        />
                        <AppFormSection
                            title="מיסוי"
                            section="taxes"
                            fields={fields}
                            xs={12}
                            md={6}
                            lg={4}
                        />
                    </AppFormContainer>
                    {/* <Stack>
                        {Object.entries(fields).map(([key, field], index) => {
                          return (
                            <FormField
                              key={index}
                              label={field.label || `שדה ${index}`}
                              type={field.type}
                              name={key}
                              options={field.meta?.options}
                              // value={values[key]}
                            />
                          )
                        })}
                      </Stack>
                    </AppFormSection> */}
                </Form>
            </Formik>
        </Page>
    )

    return page
}

export default CompanyForm
