import { useContext } from 'react'
import { fetch, fetchMinimalCompanyInfo } from '../../helpers/fetch.helper'
import AuthContext from '../../units/auth/AuthContext'
import AuthStorage from '../../units/auth/AuthStorage'
import FetchContext from './FetchContext'

export default function FetchProvider({ children, version }) {
    const authenticator = useContext(AuthContext)
    const authStorage = new AuthStorage()

    function contextualFetch(options) {
        const { headers, ...rest } = options

        const contextualOptions = {
            ...rest,
            headers: {
                ...headers,
                'X-Client-Version': version,
            },
        }
        return fetch(contextualOptions)
    }

    async function authenticate(data) {
        const { jwt, user } = await contextualFetch({
            method: 'post',
            path: `/auth/local`,
            data,
            responseHandler: (response) => response.data,
            authorize: false,
        })
        await authenticator.updateAuthentication(jwt, user)
    }

    async function authenticatedFetch(options) {
        const token = authStorage.getAuthenticationToken()
        if (token != null) {
            const { headers, ...rest } = options
            const authenticatedOptions = {
                ...rest,
                headers: {
                    ...headers,
                    'Authorization': `Bearer ${token}`,
                },
            }
            return contextualFetch(authenticatedOptions)
        }
        else {
            throw new Error('Not authenticated. Cannot fetch.')
        }
    }

    async function fetchDocument(id) {
        return authenticatedFetch({
            path: `/documents/${id}`,
        })
    }

    async function fetchDocumentForDocumentPage(id) {
        const params = {
            fields: [
                'id',
                'type',
                'kind',
                'editor',
                'schedule',
                'status',
            ],
            populate: {
                entry: {
                    fields: [
                        'id',
                        'status',
                    ],
                },
                check: {
                    fields: [
                        'id',
                        'number',
                        'bank',
                        'branch',
                        'account',
                        'currency',
                    ],
                },
                voucher27: {
                    fields: [
                        'id',
                        'asmachtaA',
                        'beneficiaryCode',
                        'asmachtaB',
                        'currency',
                    ],
                },
                voucher42: {
                    fields: [
                        'id',
                        'barcode',
                    ],
                },
                voucher38: {
                    fields: [
                        'id',
                        'barcode',
                    ],
                },
                images: {
                    fields: [
                        'id',
                        'type',
                    ],
                    populate: {
                        file: {
                            fields: [
                                'id',
                                'url',
                            ],
                        },
                    },
                },
            },
        }
        return authenticatedFetch({
            path: `/documents/${id}`,
            params,
        })
    }

    async function fetchUser() {
        return authenticatedFetch({
            path: `/users/me`,
            responseHandler: (response) => response.data,
        })
    }

    async function fetchOneCompany(id) {
        return authenticatedFetch({
            path: `/companies/${id}`,
        })
    }

    async function fetchCompanyName(id) {
        const company = await fetchOneCompany(id)
        return company.companyName
    }

    async function fetchEntriesForEntriesPage(company) {
        const params = {
            populate: {
                documents: {
                    fields: [
                        'id',
                        'type',
                    ],
                },
            },
            filters: {
                company: {
                    id: company.id,
                },
            },
        }
        const entries = await authenticatedFetch({
            path: `/entries`,
            params,
        })
        return entries
    }

    async function fetchEntriesForActivePage() {
        const params = {
            populate: {
                documents: {
                    fields: [
                        'id',
                        'type',
                    ],
                },
            },
            filters: {
                status: {
                    $in: [
                        'New',
                        'Invalid',
                        'Compiled',
                    ],
                },
            },
        }
        const entries = await authenticatedFetch({
            path: `/entries`,
            params,
        })
        return entries
    }

    async function fetchEntryForEntryPage(id) {
        const params = {
            fields: [
                'id',
                'status',
            ],
            populate: {
                documents: {
                    fields: [
                        'id',
                        'type',
                        'kind',
                        'editor',
                        'currency',
                        'schedule',
                        'status',
                    ],
                    populate: {
                        images: {
                            fields: [
                                'id',
                                'type',
                            ],
                            populate: {
                                file: {
                                    fields: [
                                        'id',
                                        'url',
                                    ],
                                },
                            },
                        },
                        check: {
                            fields: [
                                'id',
                                'number',
                                'createdAt',
                            ],
                        },
                        voucher42: {
                            fields: [
                                'id',
                                'barcode',
                                'beneficiaryCode',
                                // 'voucherType',
                                'createdAt',
                                'path',
                            ],
                        },
                        voucher38: {
                            fields: [
                                'id',
                                'barcode',
                                'beneficiaryCode',
                                // 'voucherType',
                                'createdAt',
                                'path',
                            ],
                        },
                        voucher27: {
                            fields: [
                                'id',
                                'barcode',
                                'beneficiaryCode',
                                // 'voucherType',
                                'createdAt',
                                'path',
                            ],
                        },
                    },
                },
            },
        }
        return authenticatedFetch({
            path: `/entries/${id}`,
            params,
        })
    }

    async function fetchOneEntry(id) {
        return authenticatedFetch({
            path: `/entries/${id}`,
        })
    }

    async function fetchCompaniesForCompaniesPage(search) {
        const searchParam = search ? { search } : null
        const params = {
            ...searchParam,
            populate: {
                entries: {
                    fields: [
                        'id',
                        'status',
                    ],
                },
            },
        }
        const companies = await authenticatedFetch({
            path: `/companies`,
            params,
        })
        return companies
    }

    function isAuthenticated() {
        return authStorage.isAuthenticated()
    }

    const fetcher = {
        fetch: contextualFetch,
        authenticatedFetch,
        fetchDocument,
        fetchDocumentForDocumentPage,
        fetchUser,
        fetchOneCompany,
        fetchCompanyName,
        fetchMinimalCompanyInfo,
        fetchOneEntry,
        fetchEntryForEntryPage,
        fetchCompaniesForCompaniesPage,
        fetchEntriesForEntriesPage,
        fetchEntriesForActivePage,
        authenticate,
        isAuthenticated,
    }

    return (
        <FetchContext.Provider value={fetcher}>
            {children}
        </FetchContext.Provider>
    )
}
