import * as yup from 'yup'
import {
    firstMeta,
    numericMeta,
    requiredCurrencyField,
    requiredNumericStringField,
} from './common'

const schema = yup.object().shape({
    currency: requiredCurrencyField(yup, 'סכום רשום על השיק', firstMeta),
    number: requiredNumericStringField(yup, 'מס\' שיק', numericMeta),
    bank: requiredNumericStringField(yup, 'מס\' בנק', numericMeta),
    branch: requiredNumericStringField(yup, 'מס\' סניף', numericMeta),
    account: requiredNumericStringField(yup, 'מס\' חשבון', numericMeta),
})

// const schema = yup.object.shape({
//   companyId: yup
//     .string()
//     .label('מזהה החברה')
//     .required('נא להזין מזהה חברה'),
//   companyName: yup
//     .string()
//     .label('שם החברה')
//     .required('נא להזין שם החברה'),
//   city: yup
//     .string()
//     .label('עיר')
//     .required('נא להזין עיר'),
//   street: yup
//     .string()
//     .label('רחוב')
//     .required('נא להזין רחוב'),
//   addressNumber: yup
//     .string()
//     .label('מספר הכתובת')
//     .required('נא להזין מספר הכתובת'),
//   poBox: yup
//     .string()
//     .label('תא דואר')
//     .required('נא להזין תא דואר'),
//   companyType: yup
//     .string()
//     .label('סוג החברה')
//     .required('נא להזין סוג החברה')
//     .meta({
//       options: companyTypeOptions,
//       // options: [
//       //   {
//       //     label: 'חברה ציבורית',
//       //     value: 'Public_Company',
//       //   },
//       //   {
//       //     label: 'חברה פרטית',
//       //     value: 'Private_Company',
//       //   },
//       //   {
//       //     label: 'עוסק מורשה',
//       //     value: 'Authorized_Contractor',
//       //   },
//       //   {
//       //     label: 'עוסק פטור',
//       //     value: 'Exempt_Contractor',
//       //   },
//       //   {
//       //     label: 'ארגון ללא מטרות רווח',
//       //     value: 'Non_Profit_Organization',
//       //   },
//       // ],
//     })
//     // .oneOf([
//     //   'Public_Company',
//     //   'Private_Company',
//     //   'Authorized_Contractor',
//     //   'Exempt_Contractor',
//     //   'Non_Profit_Organization',
//     // ])
//     .oneOf(companyTypeOptions.map((option) => option.value)),
//     // [
//     //   'Public_Company',
//     //   'Private_Company',
//     //   'Authorized_Contractor',
//     //   'Exempt_Contractor',
//     //   'Non_Profit_Organization',
//     // ]),
//   reportingPeriodType: yup
//     .string()
//     .label('תקופת הדיווח')
//     .required('נא להזין תקופת הדיווח')
//     .meta({
//       options: reportingPeriodTypeOptions,
//       // [
//       //   {
//       //     label: 'אחת לחודש',
//       //     value: 'OnceInAMonth',
//       //   },
//       //   {
//       //     label: 'אחת לחודשיים',
//       //     value: 'OnceInTwoMonths',
//       //   },
//       // ]
//     })
//     .oneOf(reportingPeriodTypeOptions.map((option) => option.value)),
//     // [
//     //   'OnceInAMonth',
//     //   'OnceInTwoMonths',
//     // ]),
// })

export default schema
