import { createContext, useContext, useEffect, useState } from 'react'
import PlatformContext from '../platform/PlatformContext'

const defaultAppMessage = null

export const AppMessageContext = createContext(defaultAppMessage)

export function AppMessageProvider({ children }) {
    const [message, setMessage] = useState(defaultAppMessage)
    const [messageTimeoutHandle, setMessageTimeoutHandle] = useState()
    const platform = useContext(PlatformContext)

    useEffect(() => {
        if (platform != null && platform.isDesktopApp) {
            platform.onMessage((value) => {
                setMessage(value)
            })
        }
    }, [platform])

    useEffect(() => {
        if (message != null) {
            if (messageTimeoutHandle != null) {
                clearTimeout(messageTimeoutHandle)
            }

            const handle = setTimeout(() => {
                setMessage(null)
            }, 5000)

            setMessageTimeoutHandle(handle)
        }
    }, [message])

    return (
        <AppMessageContext.Provider value={message}>
            {children}
        </AppMessageContext.Provider>
    )
}
