import {
    Card,
    CardContent,
    CardHeader,
    Grid,
    Stack,
} from '@mui/material'
import FormField from 'components/forms/FormField'

function AppFormSection({
    title,
    section,
    fields,
    children,
    disabled,
    ...props
}) {
    return (
        <Grid item {...props}>
            <Card>
                <CardHeader title={title} />
                <CardContent>
                    {fields && (
                        <Stack>
                            {Object.entries(fields).map(([key, field], index) => {
                                return field.meta?.section === section ? (
                                    <FormField
                                        key={index}
                                        label={field.label || `שדה ${index}`}
                                        type={field.type}
                                        name={key}
                                        disabled={disabled}
                                        options={field.meta?.options}
                                        autoFocus={field.meta?.autoFocus && !disabled}
                                        autoComplete={field.meta?.autoComplete}
                                        className={{
                                            ltr: field.meta?.forceLtr === true,
                                            right: field.meta?.forceRight === true,
                                        }}
                                    />
                                ) : null
                            })}
                        </Stack>
                    )}
                    {children}
                </CardContent>
            </Card>
        </Grid>
    )
}

export default AppFormSection
