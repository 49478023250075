import { Grid } from '@mui/material'
import MDBox from 'components/MDBox'

function AppFormContainer({ children }) {
    return (
        <MDBox>
            <MDBox mt={1.625}>
                <Grid container spacing={2}>
                    {children}
                </Grid>
            </MDBox>
        </MDBox>
    )
}

export default AppFormContainer
