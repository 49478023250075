export class BrowserPlatform {
    get name() {
        return 'browser'
    }

    get router() {
        return 'browser'
    }

    get env() {
        return {}
    }

    get isDesktopApp() {
        return false
    }

    async getAppVersion() {
        const { version } = await import('../../../package.json')
        return version
    }

    async checkForUpdates() {
        console.log('checkForUpdates is not supported on this platform')
    }

    readFileSync(path) {
        console.log('readFileSync is not supported on this platform')
    }

    existsSync(path) {
        console.log('existsSync is not supported on this platform')
        return false
    }

    showMessageBoxSync({ title, message }) {
        alert(`${title}: ${message}`)
    }

    onMessage(callback) {
        console.log('onMessage is not supported on this platform')
    }
}
