import * as Sentry from '@sentry/react'

export function initErrorReporter() {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
    })
}

export function reportError(error) {
    Sentry.captureException(error)
}
