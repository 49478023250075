import { React } from 'react'
import StatusBadge from '../../StatusBadge'

export function createCustomCellWithStatusBadge(field) {
    const format = field.meta?.format
    const customCell =
        format === 'status-badge'
            ? {
                sortDescFirst: true,
                Cell: (cell) => {
                    return (
                        <StatusBadge
                            status={cell.value || 'New'}
                            sx={{
                                marginInlineStart: 0,
                            }}
                        />
                    )
                },
            } : null
    return customCell
}
