import checkObject from './main'

const checkCardArray = (document) => {
    const obj = document.check === null ? document : document.check
    return [
        checkObject.number,
        checkObject.createdAt,
    ].map(field => {
        const value = field.custom
            ? field.custom(obj)
            : obj[field.accessor]
        // return `${field.Header}: ${value}`
        return { [field.Header]: value }
    })
}

export default checkCardArray
