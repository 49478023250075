import * as yup from 'yup'

const schema = yup.object({
    id: yup
        .number()
        .label('מספר מזהה'),
    status: yup
        .number()
        .label('סטטוס')
        .meta({
            format: 'status-badge',
        }),
    numberOfDocuments: yup
        .number()
        .label('מספר מסמכים'),
    documentTypes: yup
        .number()
        .label('סוגי מסמכים'),
    createdAt: yup
        .string()
        .label('תאריך יצירה')
        .meta({
            format: 'date-string',
        }),
    updatedAt: yup
        .string()
        .label('עדכון אחרון')
        .meta({
            format: 'date-string',
        }),
})

export default schema
