import { React, useEffect, useState } from 'react'
import schema from '../../schemas/entry'
import SchemaTable from '../table/SchemaTable'
import MDButton from '../MDButton'

const types = {
    check: {
        label: 'שיק',
        order: 1,
    },
    voucher27: {
        label: 'מגנטי',
        order: 2,
    },
    voucher38: {
        label: 'ברקוד (38)',
        order: 3,
    },
    voucher42: {
        label: 'ברקוד (42)',
        order: 4,
    },
    default(label) {
        return {
            label,
            order: 4,
        }
    },
}

export default function ({
    entries,
    busyEntryIds,
    navigateToEntry,
    deleteEntry,
}) {
    const [preparedEntries, setPreparedEntries] = useState([])

    useEffect(() => {
        const prepareEntries = async (entries) => {
            if (entries == null) {
                return []
            }

            const preparedEntries = entries.map((entry) => {
                const documents = entry.documents

                const uniqueTypes = documents
                    .reduce(
                        (set, document) => set.add(document.type),
                        new Set(),
                    )
                const typesString = Array.from(uniqueTypes.values())
                    .map((value) => value in types ? types[value] : types.default(value))
                    .toSorted((type1, type2) => type1.order - type2.order)
                    .map((type) => type.label)
                    .join(', ')

                return {
                    ...entry,
                    numberOfDocuments: documents.length,
                    documentTypes: typesString,
                }
            })

            setPreparedEntries(preparedEntries)
        }

        prepareEntries(entries)
            .catch((error) => console.error(error))

    }, [entries])

    const fieldIds = ['id', 'status', 'numberOfDocuments', 'documentTypes', 'createdAt', 'updatedAt']

    const actionsColumn = {
        Header: 'פעולה',
        width: 300,
        accessor: 'action',
        enableSorting: false,
        Cell: (cell) => {
            const { id, status, disabled } = cell.row.values
            const isDisabled =
                disabled
                || (status && ['compiled', 'sent'].includes(status.toLowerCase()))
            return (<>
                <MDButton
                    disabled={isDisabled}
                    onClick={(e) => {
                        e.stopPropagation()
                        deleteEntry(id)
                    }}
                    variant="outlined"
                    color="warning"
                    sx={{
                        marginInlineEnd: 1,
                    }}
                >
                    מחיקה
                </MDButton>
            </>)
        },
    }

    const nonNullEntries = preparedEntries || []
    const entriesWithDisabledStatus = nonNullEntries.map((entry) => {
        const { id: entryId } = entry
        const disabled = busyEntryIds.includes(entryId)
        return {
            ...entry,
            disabled,
        }
    })

    return (
        <SchemaTable
            schema={schema}
            data={entriesWithDisabledStatus}
            fieldIds={fieldIds}
            sortFieldId={'updatedAt'}
            columns={[actionsColumn]}
            canSearch={true}
            onRowClick={(e, row) => {
                const entryId = row.original.id
                navigateToEntry(entryId)
            }}
        />
    )
}
