/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 The boxShadow() function helps you to create a box shadow for an element
 */

// Material Dashboard 2 PRO React helper functions
import rgba from 'assets/theme-dark/functions/rgba'
import pxToRem from 'assets/theme-dark/functions/pxToRem'

function boxShadow(offset = [], radius = [], color, opacity, inset = '') {
    const [x, y] = offset
    const [blur, spread] = radius

    return `${inset} ${pxToRem(x)} ${pxToRem(y)} ${pxToRem(blur)} ${pxToRem(spread)} ${rgba(
        color,
        opacity,
    )}`
}

export default boxShadow
