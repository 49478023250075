import React, { useContext, useEffect, useState } from 'react'
import { Form, Formik } from 'formik'
import { Grid } from '@mui/material'
// import { FormattedMessage } from 'react-intl'
import AuthContext from 'units/auth/AuthContext'
import Scanner from 'units/scanner/Scanner.component'
import { fillIn } from '../../helpers/utility.helper'
import PlatformContext from 'context/platform/PlatformContext'
import colors from 'assets/theme/base/colors'
// import { voucherTypesArray, voucherNamesArray } from './voucherTypes'
import Page from '../pages/Page'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import MDBadge from 'components/MDBadge'
import AppFormContainer from 'components/forms/AppFormContainer'
import AppFormSection from 'components/forms/AppFormSection'
import { fileToDataUrl, prepareFormData } from 'helpers/file.helper'
import schema from '../../schemas/magneticVoucherSchema'

const imagesSpec = [
    {
        key: 'F1',
        fieldName: 'Voucher_Front_tif',
        imageType: 'tif',
        label: 'תמונה קדמית מסוג TIF',
        canDisplayImage: false,
    },
    {
        key: 'R1',
        fieldName: 'Voucher_Back_tif',
        imageType: 'tif',
        label: 'תמונה אחורית מסוג TIF',
        canDisplayImage: false,
    },
    {
        key: 'F2',
        fieldName: 'Voucher_Front_jpg',
        imageType: 'jpg',
        label: 'תמונה קדמית מסוג JPG',
        canDisplayImage: true,
    },
    {
        key: 'R2',
        fieldName: 'Voucher_Back_jpg',
        imageType: 'jpg',
        label: 'תמונה אחורית מסוג JPG',
        canDisplayImage: true,
    },
]

function MagneticVoucherEditor({
    document,
    mode,
    documentCommands,
    busy,
    readOnly,
    setDocumentDetailMutation,
}) {
    const { token } = useContext(AuthContext)

    const [initialValues, setInitialValus] = useState({
        asmachtaA: '',
        beneficiaryCode: '',
        asmachtaB: '',
        currency: '',
    })

    const defaultImages = imagesSpec.reduce((result, imageSpec) => ({
        ...result,
        [imageSpec.fieldName]: null,
    }), {})
    const [images, setImages] = useState(defaultImages)

    const platform = useContext(PlatformContext)

    const schemaDescription = schema.describe()
    const { fields } = schemaDescription

    useEffect(() => {
        setInitialStateFunc()
    }, [mode])

    const createImagesObject = (imagesDetails) => {
        const imagesObject = imagesSpec.reduce((result, imageSpec) => {
            const imageDetailFound = imagesDetails.find(imageDetail => imageDetail.type === imageSpec.fieldName)
            result[imageSpec.fieldName] = {
                name: imageSpec.fieldName,
                url: imageDetailFound ? `${process.env.REACT_APP_STRAPIURL_BASE}${imageDetailFound.file.url}?jwt=${token}` : null,
                imageType: imageSpec.imageType,
            }
            return result
        }, {})

        return imagesObject
    }

    const setInitialStateFunc = () => {
        if (mode !== 'new' && document.voucher27) {
            const { voucher27 } = document

            const objectToSet = {
                asmachtaA: voucher27?.asmachtaA,
                beneficiaryCode: voucher27?.beneficiaryCode,
                asmachtaB: voucher27?.asmachtaB,
                currency: voucher27?.currency,
            }
            setInitialValus(objectToSet)

            const documentImagesObject = createImagesObject(document.images)
            const editedImagesObject =
                Object.entries(images)
                    .filter(([, value]) => value instanceof File)
                    .reduce((result, [key, value]) => {
                        return {
                            ...result,
                            [key]: value,
                        }
                    }, {})
            setImages({
                ...documentImagesObject,
                ...editedImagesObject,
            })
        }
    }

    const handleSubmit = async (values) => {
        const data = prepareFormData(values, imagesSpec, platform)
        setDocumentDetailMutation.mutate({
            data,
            endpoint: 'Voucher27',
        })
    }

    const handleFileChange = async (event, imageSpec) => {
        const file = event.target.files[0]
        const { fieldName } = imageSpec
        if (file) {
            const dataUrl = await fileToDataUrl(file)

            setImages({
                ...images,
                [fieldName]: {
                    imageType: imageSpec.imageType,
                    name: fieldName,
                    url: dataUrl,
                },
            })
        }
    }

    const translateLinereadToObject = (lineRead) => {
        const regex = /([a-z]\d{3}[a-z])/g
        let newText = lineRead.replace(regex, '')
        const stringArray = newText.split('')
        let oneLineDigits = ''
        for (let i = 0; i < stringArray.length; i++) {
            if (stringArray[i] === '_') {
                oneLineDigits += stringArray[i]
            }
            else if (isNaN(Number(stringArray[i])) && (!isNaN(Number(stringArray[i - 1])) || stringArray[i - 1] === '_')) {
                oneLineDigits += ' '
            }
            else if (!isNaN(Number(stringArray[i]))) {
                oneLineDigits += stringArray[i]
            }
        }
        const numbersArray = oneLineDigits.split(' ')
        return {
            asmachtaA: `${fillIn(numbersArray[0], 10, '0')}`,
            beneficiaryCode: `${fillIn(`${numbersArray[1]}`, 2, '0')}${fillIn(`${numbersArray[2]}`, 5, '0')}`,
            asmachtaB: `${fillIn(numbersArray[3], 10, '0')}`,
        }
    }

    // const insertScannedData = (rawScannerText) => {
    //   const scannerTextLines = rawScannerText.split('\n')
    //   if (!scannerTextLines || !scannerTextLines[1] || (Array.isArray(scannerTextLines) && scannerTextLines.length < 2)) {
    //     platform.showMessageBoxSync({ type: 'error', buttons: [], title: 'בעיה בסורק', message: 'הסורק לא מצא מסמך בתא סריקה, אנא נסה שנית.' })
    //     return
    //   }
    //   const dataObject = JSON.parse(scannerTextLines[1])
    //   const fieldsData = translateLinereadToObject(dataObject.lineRead)
    //   const imagesObject = {}
    //   let imageExistError = false
    //   Object.entries(dataObject).forEach((dataField, index) => {
    //     if (index === 0) return
    //     const fullPath = `${baseLocation}${dataField[1]}`
    //     const fileExists = platform.existsSync(fullPath) ? true : false
    //     if (!fileExists) imageExistError = true

    //     const imageObject = {
    //       name: voucherNamesArray[index - 1],
    //       url: fileExists ? fullPath : null,
    //       imageType: fullPath ? fullPath.split('.')[fullPath.split('.').length - 1] : null,
    //     }
    //     imagesObject[voucherTypesArray[index - 1]] = imageObject
    //   })
    //   setFormData({
    //     ...formData,
    //     asmachtaA: fieldsData.asmachtaA,
    //     beneficiaryCode: fieldsData.beneficiaryCode,
    //     asmachtaB: fieldsData.asmachtaB,
    //     images: imagesObject,
    //   })
    //   setPayload({
    //     ...payload,
    //     asmachtaA: fieldsData.asmachtaA,
    //     beneficiaryCode: fieldsData.beneficiaryCode,
    //     asmachtaB: fieldsData.asmachtaB,
    //     images: imagesObject,
    //   })
    //   if (imageExistError) {
    //     platform.showMessageBoxSync({ type: 'warning', buttons: [], title: 'קבצים לא התקבלו', message: 'אחד(או יותר) מהקבצים שהסורק ייצר לא נמצאו, יש לחפש ולהעלות ידנית.' })
    //   }
    // }

    // const doActivateScanner = async () => {
    //   const scannedData = await activateScanner(platform)
    //   insertScannedData(scannedData)
    // }

    const [scannerTrigger, setScannerTrigger] = useState(false)

    const mimicScanner = async (mode) => {
        const modes = ['valid', 'invalid', 'empty']
        if (!modes.includes(mode)) {
            console.log('not a valid mode', mode)
        }

        const route = `/tools/mimic${mode}voucher27scan`
        setScannerTrigger(route)
        // let route
        // switch (mode) {
        //   case 'valid':
        //     route = '/tools/mimicvalid'
        //     break
        //   case 'invalid':
        //     route = '/tools/mimicinvalidvoucher27scan'
        //     break
        //   case 'empty':
        //     route = '/tools/mimicemptyvoucher27scan'
        //     break
        //   default:
        //     console.log('not a valid mode')
        //     console.log(mode)
        //     return
        // }

        // try {
        //   const res = await fetch({
        //     method: 'get',
        //     path: route,
        //     headers: {
        //       'Content-Type': 'application/json',
        //     },
        //     responseHandler: (response) => response,
        //     rethrow: true,
        //   })

        //   // File uploaded successfully!
        //   console.log(`res`, res)
        //   // insertScannedData(res.data)
        // }
        // catch(err) {
        //   // File upload failed!
        //   console.log(`couldn't fetch mimic of scan`)
        //   console.log(`err`, err)
        // }
    }

    const commands = [
        ...documentCommands,
        {
            label: 'הפעלת סורק',
            onClick: () => {
                setScannerTrigger(true)
                // doActivateScanner()
            },
            position: 'start',
            color: 'secondary',
            disabled: busy || readOnly || scannerTrigger,
        },
        // {
        //   label: 'חיקוי סורק תקין',
        //   // to: null,
        //   onClick: () => mimicScanner('valid'),
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
        // {
        //   label: 'חיקוי סורק לא תקין',
        //   // to: null,
        //   onClick: () => mimicScanner('invalid'),
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
        // {
        //   label: 'חיקוי סורק ריק',
        //   // to: null,
        //   onClick: () => mimicScanner('empty'),
        //   position: 'end',
        //   color: 'secondary',
        //   disabled: busy,
        // },
    ]

    // const Voucher27UploaderForm = ({
    //   handleChange,
    //   values,
    //   setFieldValue,
    //   scannerTriggered,
    // }) = {
    //   useScanner({
    //     scannerTriggered,
    //     onSuccess: ({
    //       fieldsData,
    //       imagesObject,
    //       missingImages,
    //     }) => {
    //       Object.entries(fieldsData).forEach(([key, value]) => {
    //         setFieldValue(key, value)
    //       })
    //       Object.entries(imagesObject).forEach(([key, value]) => {
    //         setFieldValue(`images.${key}`, value)
    //         const imageSpec = imagesSpec.find((imageSpec) => imageSpec.fieldName === key)
    //         setImages({
    //           ...images,
    //           [key]: {
    //             imageType: imageSpec.imageType,
    //             name: key,
    //             url: dataUrl,
    //           },
    //         })
    //       })
    //     },
    //     onError: (error) => {
    //       addNotification({
    //         type: 'error',
    //         content: error?.message,
    //       })
    //     },
    //     onDone: () => {
    //       setScannerTriggered(false)
    //     },
    //   })
    // }

    const page = (
        <Page
            title={`שובר מגנטי ${document.id}`}
            status={document.status}
            commands={commands}
        >
            <Formik
                onSubmit={(values, action) => {
                    handleSubmit(values)
                }}
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={initialValues}
            >
                {({
                    setFieldValue,
                }) => {
                    function updateItems(items) {
                        if (items) {
                            const fields = {
                                asmachtaA: `${fillIn(items[0], 10, '0')}`,
                                beneficiaryCode: `${fillIn(`${items[1]}`, 2, '0')}${fillIn(`${items[2]}`, 5, '0')}`,
                                asmachtaB: `${fillIn(items[3], 10, '0')}`,
                            }
                            // if (fields) {
                            Object.entries(fields).forEach(([key, value]) => {
                                setFieldValue(key, value)
                            })
                            // }
                        }
                    }

                    return (
                        <Form id="form">
                            <Scanner
                                scannerTrigger={scannerTrigger}
                                setScannerTrigger={setScannerTrigger}
                                setFieldValue={setFieldValue}
                                setImages={setImages}
                                imagesSpec={imagesSpec}
                                updateItems={updateItems}
                            />
                            <AppFormContainer>
                                <AppFormSection
                                    title="פרטי השובר"
                                    section="details"
                                    fields={fields}
                                    disabled={readOnly}
                                    xs={12}
                                    md={6}
                                    lg={4}
                                />
                                <AppFormSection title="תמונות" xs={12} md={12} lg={8}>
                                    <Grid container item columns={8}>
                                        {imagesSpec.map((imageSpec, index) => {
                                            const { label, fieldName, canDisplayImage } = imageSpec

                                            const image = images[fieldName]

                                            let badgeMessage = null
                                            let badgeColor = null
                                            if (image?.url == null) {
                                                badgeMessage = 'לא נבחרה תמונה'
                                                badgeColor = 'warning'
                                            }
                                            else if (image?.imageType === 'tif') {
                                                badgeMessage = 'קיימת תמונה, אבל אי אפשר להציג אותה'
                                                badgeColor = 'success'
                                            }

                                            return (
                                                <Grid item key={index} xs={4}>
                                                    <label className="btn btn-primary" htmlFor={fieldName}>
                                                        <MDBox
                                                            mx={1}
                                                            mb={2}
                                                            py={1}
                                                            px={2}
                                                            bgColor={colors.grey['200']}
                                                            shadow="lg"
                                                            sx={{
                                                                borderRadius: 2,
                                                                cursor: readOnly ? 'not-allowed' : 'pointer',
                                                                display: 'flex',
                                                                flexDirection: 'column',
                                                            }}
                                                        >
                                                            <MDTypography variant="h3" pb={1}>
                                                                {label}
                                                            </MDTypography>
                                                            {badgeMessage && (
                                                                <MDBadge
                                                                    badgeContent={badgeMessage}
                                                                    color={badgeColor}
                                                                    circular={true}
                                                                    variant="contained"
                                                                    size="lg"
                                                                />
                                                            )}
                                                            {canDisplayImage && image?.url && (
                                                                <img alt={label} src={image.url} />
                                                            )}
                                                            <MDBox>
                                                                <MDBox>
                                                                    <input
                                                                        type="file"
                                                                        name={fieldName}
                                                                        id={fieldName}
                                                                        disabled={readOnly}
                                                                        style={{ display: 'none' }}
                                                                        onChange={(e) => {
                                                                            setFieldValue(`images.${fieldName}`, e.currentTarget.files[0])
                                                                            handleFileChange(e, imageSpec)
                                                                        }}
                                                                    />
                                                                </MDBox>
                                                            </MDBox>
                                                            <MDTypography
                                                                color="primary"
                                                                pt={1}
                                                                sx={{
                                                                    textAlign: 'end',
                                                                }}
                                                            >
                                                                בחירת קובץ באופן ידני
                                                            </MDTypography>
                                                        </MDBox>
                                                    </label>
                                                </Grid>
                                            )
                                        })}
                                    </Grid>
                                </AppFormSection>
                            </AppFormContainer>
                        </Form>
                    )
                }}
            </Formik>
        </Page>
    )

    return page
}

export default MagneticVoucherEditor
