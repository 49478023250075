import { CircularProgress, circularProgressClasses } from '@mui/material'
import MDBox from 'components/MDBox'

export default function Loader({ visible, size, minHeight }) {
    return (
        <MDBox
            sx={{
                display: 'flex',
                visibility: visible ? 'visible' : 'hidden',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight,
            }}
        >
            <CircularProgress
                color="info"
                size={size || 100}
                sx={{
                    [`& .${circularProgressClasses.circle}`]: {
                        strokeLinecap: 'round',
                    },
                }}
            />
        </MDBox>
    )
}
