import * as yup from 'yup'
import {
    firstMeta,
    requiredNumericStringField,
} from './common'

const supportedVoucherDigitsPattern = /^(\d{38}|\d{42})$/

const schema = yup.object().shape({
    barcode: requiredNumericStringField(yup, 'ברקוד', firstMeta)
        .matches(supportedVoucherDigitsPattern, (options) => {
            return `הברקוד שהוזן בפורמט שלא נתמך. כרגע נתמכים ברקודים בעלי 38 או 42 ספרות בלבד. לברקוד שהוזן יש ${options.value.length} ספרות.`
        }),
})

export default schema
