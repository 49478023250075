import { useNotificationContext } from 'units/notifications/NotificationContext'
import useScanner from './useScanner'

function Scanner({
    scannerTrigger,
    setScannerTrigger,
    setFieldValue,
    updateItems,
    setImages,
    imagesSpec,
}) {

    const { addNotification } = useNotificationContext()

    const baseLocation = 'C:\\images\\'

    useScanner({
        scannerTrigger,
        onSuccess: ({
            items,
            fields,
            images,
            imagesObject,
            missingImages,
        }) => {
            updateItems(items)
            // if (fields) {
            //   Object.entries(fields).forEach(([key, value]) => {
            //     setFieldValue(key, value)
            //   })
            // }

            let resolvedImagesObject
            if (imagesObject) {
                resolvedImagesObject = imagesObject
            }
            else {
                resolvedImagesObject = imagesSpec.reduce((result, imageSpec) => {
                    const fileName = images[`${imageSpec.key}Name`]
                    const fullPath = `${baseLocation}${fileName}`
                    return {
                        ...result,
                        [imageSpec.fieldName]: {
                            imageType: imageSpec.imageType,
                            name: imageSpec.key,
                            url: fullPath,
                        },
                    }
                }, {})
            }
            Object.entries(resolvedImagesObject).forEach(([key, value]) => {
                setFieldValue(`images.${key}`, value)
            })
            setImages(resolvedImagesObject)
        },
        onError: (error) => {
            addNotification({
                type: 'error',
                content: error?.message,
            })
        },
        onDone: () => {
            setScannerTrigger(false)
        },
    })

    return null
}

export default Scanner
