import { useContext, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useConfirm } from 'material-ui-confirm'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import AuthContext from 'units/auth/AuthContext'
import { useNotificationContext } from 'units/notifications/NotificationContext'
import useQueryLibrary from 'hooks/useQueryLibrary'
import FetchContext from '../../context/fetch/FetchContext'
import BarcodeVoucherEditor from '../../components/vouchers/BarcodeVoucherEditor'
import MagneticVoucherEditor from '../../components/vouchers/MagneticVoucherEditor'
import CheckUploader from '../../components/CheckUploader/CheckUploader.component'
import NoEditor from '../../components/documents/NoEditor'
import './AddDocument.styles.scss'

function AddDocument(props) {
    const { token } = useContext(AuthContext)

    // const [documentInfo, setDocumentInfo] = useState(null)

    const { companyId, entryId, documentId } = useParams()

    // const { data: entry } = useQuery(
    //   ['entries', entryId],
    //   async () => {
    //     const data = await fetchOneEntry(entryId)
    //     return data
    //   },
    //   // {
    //   //   onSuccess: (data) => {
    //   //     const documents = data.documents ? data.documents : []
    //   //     setCheckDocument(documents.find(document => document.type === 'check'))
    //   //     setVoucherDocuments(documents.filter(document =>
    //   //       document.type === 'voucher42'
    //   //       || document.type === 'voucher27'
    //   //     ))
    //   //     const hasDocuments = checkDocument != null && voucherDocuments.length !== 0
    //   //     setIsAnyDocuments(Boolean(hasDocuments))
    //   //     if (data.status === 'Compiled' || data.status === 'Sent') {
    //   //       setIsEntryDisabled(true)
    //   //     }
    //   //   },
    //   // }
    // )

    const navigate = useNavigate()

    const { addNotification } = useNotificationContext()

    const confirm = useConfirm()

    const queryClient = useQueryClient()
    const queryLibrary = useQueryLibrary()

    const fetcher = useContext(FetchContext)

    const { data: user } = useQuery({
        queryKey: queryLibrary.meQuery(),
        queryFn: async () => {
            const data = await fetcher.fetchUser()
            return data
        },
    })

    const { data: document } = useQuery({
        queryKey: queryLibrary.oneDocumentForDocumentPageQuery(documentId),
        queryFn: async () => {
            const data = await fetcher.fetchDocumentForDocumentPage(documentId)
            return data
        },
    })
    console.log('document', document)

    const setDocumentDetailMutation = useMutation({
        mutationFn: ({ data, endpoint }) => {
            console.log('mutating')
            setBusy(true)

            return fetcher.authenticatedFetch({
                method: 'put',
                path: `/documents/set${endpoint}Data/${document.id}`,
                data,
                responseHandler: (response) => response,
            })
        },
        onSuccess: (data, variables, context) => {
            console.log('success', data, variables, context)

            queryClient.invalidateQueries(
                queryLibrary.oneDocumentQuery(document.id),
            )
            queryClient.invalidateQueries(
                queryLibrary.oneEntryQuery(entryId),
            )
            queryClient.invalidateQueries(
                queryLibrary.oneDocumentForDocumentPageQuery(document.id),
            )
            queryClient.invalidateQueries(
                queryLibrary.oneEntryForEntryPageQuery(document.id),
            )

            addNotification({
                type: 'success',
                content: 'השובר נשמר בהצלחה',
            })

            const modifiedDocument = data?.data?.data
            if (modifiedDocument?.id && modifiedDocument.status === 'valid') {
                navigate(`/companies/${companyId}/entries/${entryId}`)
            }
        },
        onError: (error) => {
            console.error(error)

            addNotification({
                type: 'error',
                content: error?.response?.data?.error?.message || error?.message,
            })
        },
        onSettled: (data) => {
            console.log('mutation settled')

            setBusy(false)
        },
    })

    // useEffect(() => {
    //   const checkIfEntryBelongs = async () => {
    //     const user = await fetchUser()
    //     const entry = await fetchOneEntry(entryId)

    //     if (checkIfCompanyBelong(user, entry.company.id) === false) {
    //       RedirectToURL('/companies', props)
    //     }
    //   }

    //   // const fetchAndSetDocument = async () => {
    //   //   const document = await fetchDocument(documentId)
    //   //   setDocumentInfo(document)
    //   // }

    //   checkIfEntryBelongs()
    //     // .then(() => fetchAndSetDocument())
    // }, [])

    // const [userInfo, setUserInfo] = useState()


    // const fetchAndSetUser = async () => {
    //   const user = await fetchUser()
    //   if (checkIfCompanyBelong(user, companyId)) {
    //     setUserInfo(user)
    //   }
    //   else {
    //     navigate('/companies', props)
    //   }
    // }

    const [voucherCount, setVoucherCount] = useState(0)
    const [checkCount, setCheckCount] = useState()
    const updateCount = (type) => {
        // eslint-disable-next-line default-case
        switch (type) {
            case 'Voucher':
                setVoucherCount(voucherCount + 1)
                break
            case 'Check':
                setCheckCount(checkCount + 1)
                break
        }
    }

    const [busy, setBusy] = useState(false)

    const entryStatus = document?.entry?.status?.toLowerCase()

    const documentType = document?.type?.toLowerCase()
    const documentDetail = document && documentType ? document[documentType] : null

    const editor = document?.editor?.toLowerCase()

    const mode = useMemo(() => {
        if (entryStatus == null || document == null || documentType == null) {
            return null
        }
        else if (['compiled', 'sent'].includes(entryStatus)) {
            return 'view'
        }
        else if (document[documentType] == null) {
            return 'new'
        }
        else {
            return 'edit'
        }
    }, [entryStatus, documentDetail])

    const deleteDocumentMutation = useMutation({
        mutationFn: (documentId) => {
            return fetcher.authenticatedFetch({
                method: 'delete',
                path: `/documents/${documentId}`,
                responseHandler: (response) => response,
            })
        },
        onSuccess: async () => {
            await queryClient.invalidateQueries(
                queryLibrary.oneEntryQuery(entryId),
            )

            addNotification({
                type: 'success',
                content: 'המסמך נמחק בהצלחה',
            })

            navigate(`/companies/${companyId}/entries/${entryId}`)
        },
        onError: (error) => {
            addNotification({
                type: 'error',
                content: error?.message,
            })
        },
    })

    const deleteDocument = async (documentId) => {
        try {
            await confirm({
                title: `האם למחוק את המסמך ${documentId}? `,
                description: 'שימו לב! אי אפשר לשחזר את הנתונים אחרי המחיקה.',
            })
            deleteDocumentMutation.mutate(documentId)
        }
        catch (error) {
            if (error) {
                addNotification({
                    type: 'error',
                    content: error?.message,
                })
            }
        }
    }

    const readOnly = mode === 'view'

    const documentCommands = [
        {
            label: 'שמירה',
            type: 'submit',
            position: 'start',
            color: 'primary',
            form: 'form',
            disabled: busy || readOnly,
        },
        {
            label: 'מחיקה',
            onClick: (e) => deleteDocument(document.id),
            position: 'end',
            color: 'warning',
            disabled: busy || readOnly,
        },
        {
            label: 'איפוס',
            type: 'reset',
            position: 'end',
            color: 'warning',
            form: 'form',
            disabled: busy || readOnly,
        },
        {
            scheme: 'link',
            icon: 'arrow_upward',
            to: `/companies/${companyId}/entries/${entryId}`,
            position: 'end',
            color: 'info',
            disabled: busy,
        },
    ]

    const getDocumentEditor = () => {
        const documentEditors = {
            // by type field // todo: remove the type field options and only use the editor field
            voucher42: BarcodeVoucherEditor,
            voucher38: BarcodeVoucherEditor,
            voucher27: MagneticVoucherEditor,
            check: CheckUploader,

            // by editor field
            barcode_voucher: BarcodeVoucherEditor,
            magnetic_voucher: MagneticVoucherEditor,
            magnetic_check: CheckUploader,
        }

        const DocumentEditor = documentEditors[editor || documentType] || NoEditor
        if (document != null) {
            return (
                <DocumentEditor
                    {...props}
                    // document={documentInfo}
                    document={document}
                    token={token}
                    mode={mode}
                    documentCommands={documentCommands}
                    busy={busy}
                    setBusy={setBusy}
                    readOnly={readOnly}
                    setDocumentDetailMutation={setDocumentDetailMutation}
                />
            )
        }
    }

    const UploadComponent = getDocumentEditor()

    // const page =
    //   <Page>
    //     {/* <div className="Content"> */}
    //       {/* <div className="container-fluid" id="EntriesWizard"> */}
    //       {/* <UploadComponent /> */}
    //       {( UploadComponent )}
    //       {/* </div> */}
    //     {/* </div> */}
    //   </Page>
    return (UploadComponent)
}

export default AddDocument
