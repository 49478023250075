import voucherObject from './main'

const voucherCardArray = (voucher) => {
    return [
        voucherObject.barcode,
        voucherObject.voucherType,
        voucherObject.createdAt,
    ].map(field => {
        const value = field.custom
            ? field.custom(voucher)
            : voucher[field.accessor]
        // return `${field.Header}: ${value? value: 'אין מידע'}`
        return { [field.Header]: value ? value : 'אין מידע' }
    })
}

export default voucherCardArray
