/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

/**
 The rgba() function helps you to create a rgba color code, it uses the hexToRgb() function
 to convert the hex code into rgb for using it inside the rgba color format.
 */

// Material Dashboard 2 PRO React helper functions
import hexToRgb from 'assets/theme-dark/functions/hexToRgb'

function rgba(color, opacity) {
    return `rgba(${hexToRgb(color)}, ${opacity})`
}

export default rgba
