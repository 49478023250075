import { useContext, useEffect, useState } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { useQueryClient } from '@tanstack/react-query'
import { updateAuthorizationHeader } from 'helpers/fetch.helper'
import AuthContext from 'units/auth/AuthContext'
import Loader from 'components/Loader'

const AuthWrapper = () => {
    const { user, token, authenticated } = useContext(AuthContext)

    const location = useLocation()
    const [from, setFrom] = useState(null)
    const [previousUserId, setPreviousUserId] = useState(null)

    const queryClient = useQueryClient()

    useEffect(() => {
        if (!authenticated) {
            queryClient.invalidateQueries()
            // this.navigate('/')
        }
    }, [authenticated])

    useEffect(() => {
        if (user && user.id !== previousUserId) {
            setPreviousUserId(user.id)
        }

        let target = '/'
        if (previousUserId == null) {
            target = location
        }
        else if (user && user.id === previousUserId) {
            target = location
        }
        setFrom(target)
    }, [previousUserId])

    let content
    if (authenticated) {
        content = <Outlet />
    }
    else if (from == null) {
        content = <Loader minHeight="50vh" />
    }
    else {
        content = <Navigate to="/auth/sign-in" replace state={{ from }} />
    }

    return content
}

export default AuthWrapper
