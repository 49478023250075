/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import { useEffect, useMemo, useState } from 'react'

// react-router-dom components
import { useLocation, NavLink } from 'react-router-dom'

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import Link from '@mui/material/Link'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'

// Material Dashboard 2 PRO React examples
import SidenavCollapse from 'examples/Sidenav/SidenavCollapse'
import SidenavList from 'examples/Sidenav/SidenavList'
import SidenavItem from 'examples/Sidenav/SidenavItem'

// Custom styles for the Sidenav
import SidenavRoot from 'examples/Sidenav/SidenavRoot'
import sidenavLogoLabel from 'examples/Sidenav/styles/sidenav'

import routes from '../../routes'

// Material Dashboard 2 PRO React context
import {
    useMaterialUIController,
    setMiniSidenav,
    setTransparentSidenav,
    setWhiteSidenav,
} from 'context/material-ui'
import DatumLoader from 'components/loaders/DatumLoader'

function Sidenav({
    color = 'info',
    brand = '',
    brandName,
    subtitle,
    ...rest
}) {
    const [openCollapse, setOpenCollapse] = useState(false)
    const [openNestedCollapse, setOpenNestedCollapse] = useState(false)
    const [controller, dispatch] = useMaterialUIController()
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller
    const location = useLocation()
    const { pathname } = location
    const collapseName = pathname.split('/').slice(1)[0]
    const items = pathname.split('/').slice(1)
    const itemParentName = items[1]
    const itemName = items[items.length - 1]
    // const [titles, setTitles] = useState({})

    let textColor = 'white'

    if (transparentSidenav || (whiteSidenav && !darkMode)) {
        textColor = 'dark'
    }
    else if (whiteSidenav && darkMode) {
        textColor = 'inherit'
    }

    const closeSidenav = () => setMiniSidenav(dispatch, true)

    useEffect(() => {
        setOpenCollapse(collapseName)
        setOpenNestedCollapse(itemParentName)
    }, [])

    useEffect(() => {
        // A function that sets the mini state of the sidenav.
        function handleMiniSidenav() {
            setMiniSidenav(dispatch, window.innerWidth < 1200)
            setTransparentSidenav(dispatch, window.innerWidth < 1200 ? false : transparentSidenav)
            setWhiteSidenav(dispatch, window.innerWidth < 1200 ? false : whiteSidenav)
        }

        /**
         The event listener that's calling the handleMiniSidenav function when resizing the window.
         */
        window.addEventListener('resize', handleMiniSidenav)

        // Call the handleMiniSidenav function to set the state with the initial value.
        handleMiniSidenav()

        // Remove event listener on cleanup
        return () => window.removeEventListener('resize', handleMiniSidenav)
    }, [dispatch, location])

    // // Render all the nested collapse items from the routes.js
    // const renderNestedCollapse = (collapse) => {
    //   const template = collapse.map(({ name, route, key, href }) =>
    //     href ? (
    //       <Link
    //         key={key}
    //         href={href}
    //         target="_blank"
    //         rel="noreferrer"
    //         sx={{ textDecoration: 'none' }}
    //       >
    //         <SidenavItem name={name} nested />
    //       </Link>
    //     ) : (
    //       <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
    //         <SidenavItem name={name} active={route === pathname} nested />
    //       </NavLink>
    //     )
    //   )

    //   return template
    // }
    // // Render the all the collpases from the routes.js
    // const renderCollapse = (collapses) =>
    //   collapses.map(({ name, collapse, route, href, key }) => {
    //     let returnValue

    //     console.log('sidenav', name, collapse, route, href, key)
    //     if (collapse) {
    //       returnValue = (
    //         <SidenavItem
    //           key={key}
    //           color={color}
    //           name={name}
    //           active={key === itemParentName ? 'isParent' : false}
    //           open={openNestedCollapse === key}
    //           onClick={({ currentTarget }) =>
    //             openNestedCollapse === key && currentTarget.classList.contains('MuiListItem-root')
    //               ? setOpenNestedCollapse(false)
    //               : setOpenNestedCollapse(key)
    //           }
    //         >
    //           {renderNestedCollapse(collapse)}
    //         </SidenavItem>
    //       )
    //     } else {
    //       returnValue = href ? (
    //         <Link
    //           href={href}
    //           key={key}
    //           target="_blank"
    //           rel="noreferrer"
    //           sx={{ textDecoration: 'none' }}
    //         >
    //           <SidenavItem color={color} name={name} active={key === itemName} />
    //         </Link>
    //       ) : (
    //         <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
    //           <SidenavItem color={color} name={name} active={key === itemName} />
    //         </NavLink>
    //       )
    //     }
    //     return <SidenavList key={key}>{returnValue}</SidenavList>
    //   })

    // // Render all the routes from the routes.js (All the visible items on the Sidenav)
    // const renderRoutes = routes.map(
    //   ({ type, name, icon, title, collapse, noCollapse, key, href, route }) => {
    //     let returnValue

    //     if (type === 'collapse') {
    //       if (href) {
    //         returnValue = (
    //           <Link
    //             href={href}
    //             key={key}
    //             target="_blank"
    //             rel="noreferrer"
    //             sx={{ textDecoration: 'none' }}
    //           >
    //             <SidenavCollapse
    //               name={name}
    //               icon={icon}
    //               active={key === collapseName}
    //               noCollapse={noCollapse}
    //             />
    //           </Link>
    //         )
    //       } else if (route) {
    //         returnValue = (
    //           <NavLink to={route} key={key}>
    //             <SidenavCollapse
    //               name={name}
    //               icon={icon}
    //               noCollapse={noCollapse}
    //               active={key === collapseName}
    //             >
    //               {collapse ? renderCollapse(collapse) : null}
    //             </SidenavCollapse>
    //           </NavLink>
    //         )
    //       } else {
    //         returnValue = (
    //           <SidenavCollapse
    //             key={key}
    //             name={name}
    //             icon={icon}
    //             active={key === collapseName}
    //             open={openCollapse === key}
    //             onClick={() => (openCollapse === key ? setOpenCollapse(false) : setOpenCollapse(key))}
    //           >
    //             {collapse ? renderCollapse(collapse) : null}
    //           </SidenavCollapse>
    //         )
    //       }
    //     } else if (type === 'title') {
    //       returnValue = (
    //         <MDTypography
    //           key={key}
    //           color={textColor}
    //           display="block"
    //           variant="caption"
    //           fontWeight="bold"
    //           textTransform="uppercase"
    //           pl={3}
    //           mt={2}
    //           mb={1}
    //           ml={1}
    //         >
    //           {title}
    //         </MDTypography>
    //       )
    //     } else if (type === 'divider') {
    //       returnValue = (
    //         <Divider
    //           key={key}
    //           light={
    //             (!darkMode && !whiteSidenav && !transparentSidenav) ||
    //             (darkMode && !transparentSidenav && whiteSidenav)
    //           }
    //         />
    //       )
    //     } else if (name) {
    //       returnValue = (
    //         <NavLink to={route} key={key} sx={{ textDecoration: 'none' }}>
    //           <SidenavCollapse
    //             key={key}
    //             name={name}
    //             icon={icon}
    //             noCollapse
    //             active={key === collapseName}
    //           >
    //             {collapse ? renderCollapse(collapse) : null}
    //           </SidenavCollapse>
    //         </NavLink>
    //       )
    //     }

    //     return returnValue
    //   }
    // )


    const hydratedTitles = useMemo(
        () => {
            const navRoutes = routes.filter((route) => route.handle?.nav || route.children)
            const initialTitles = navRoutes.map((route) => ({
                [route.path]: 'new',
            }))
            return initialTitles
        }, [],
    )
    // setTitles(initialTitles)

    // function fetchTitle(route) {
    //   if (!route.handle?.getTitle) {
    //     return
    //   }
    //   route.handle.getTitle().then((title) => {
    //     setTitles({
    //       ...titles,
    //       [route.path]: title,
    //     })
    //   })
    //   // return title
    // }

    const prepareRoutes = (routes, basePath) => {
        const navRouteInfos = routes
            .filter((route) => route.handle?.nav)
            .map((route) => {
                // console.log('rendering route', route)
                // const title = route.handle?.title || route.path
                // const title = getTitle(route) || route.path || 'NO TITLE'
                // const key = route.id || route.handle?.key || route.path
                const partialPath = [basePath, route.path].join('/').replace('//', '/')
                return {
                    fullPath: partialPath,
                    key: partialPath,
                    icon: route.handle.icon,
                    // title: partialPath + '-' + route.handle.icon,
                    title: route.handle.title || partialPath,
                    route,
                }
            })
        const childRouteInfos = routes
            .filter((route) => route.children)
            .flatMap((route) => {
                const partialPath = [basePath, route.path].join('/').replace('//', '/')
                const childRouteInfos = prepareRoutes(route.children, partialPath)
                return [...childRouteInfos]
            })
        return [...navRouteInfos, ...childRouteInfos]
    }

    const preparedRoutes = useMemo(() => prepareRoutes(routes), [])

    const initialTitles = preparedRoutes.reduce((result, route) => {
        result[route.fullPath] = route.route?.handle?.title || <DatumLoader />
        return result
    }, {})

    const [titles, setTitles] = useState(initialTitles)
    preparedRoutes.forEach((route) => {
        const getTitle = route.route.handle?.getTitle
        if (getTitle) {
            getTitle().then((title) => {
                if (titles[route.fullPath] !== title) {
                    setTitles({
                        ...titles,
                        [route.fullPath]: title,
                    })
                }
            })
        }
    })

    const renderRoutes = (routeInfos) => {
        return routeInfos
            .map((routeInfo) => {
                return (
                    <NavLink
                        // to={partialPath}
                        to={routeInfo.fullPath}
                        key={routeInfo.key}
                        sx={{ textDecoration: 'none' }}
                    >
                        <SidenavItem
                            // key={key}
                            name={titles[routeInfo.fullPath]}
                            icon={routeInfo.icon || 'apps'}
                            // noCollapse
                            // active={key === collapseName}
                        />
                        {/* {collapse ? renderCollapse(collapse) : null} */}
                        {/* </SidenavCollapse> */}
                    </NavLink>
                )
                //       }
                //       if (route.children) {
                //         return renderRoutes(route.children, partialPath)
                //       }
            })
    }

    return (
        <SidenavRoot
            {...rest}
            variant="permanent"
            ownerState={{ transparentSidenav, whiteSidenav, miniSidenav, darkMode }}
        >
            <MDBox
                pt={2}
                pb={1}
                // px={8}
                textAlign="center"
            >
                <MDBox
                    display={{ xs: 'block', xl: 'none' }}
                    position="absolute"
                    top={0}
                    right={0}
                    p={1.625}
                    onClick={closeSidenav}
                    sx={{ cursor: 'pointer' }}
                >
                    <MDTypography variant="h6" color="secondary">
                        <Icon sx={{ fontWeight: 'bold' }}>close</Icon>
                    </MDTypography>
                </MDBox>
                <MDBox
                    component={NavLink}
                    to="/"
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                >
                    {brand && <MDBox component="img" src={brand} alt="Brand" width="4rem" />}
                    <MDBox
                        sx={(theme) => sidenavLogoLabel(theme, { miniSidenav })}
                    >
                        <MDTypography
                            component="h6"
                            variant="title"
                            // fontWeight="bold"
                            fontSize="2rem"
                            color={textColor}
                            textTransform="uppercase"
                        >
                            {brandName}
                        </MDTypography>
                        <MDTypography
                            component="div"
                            variant="subtitle"
                            // fontWeight="bold"
                            fontSize="1.2rem"
                            color={textColor}
                            textTransform="uppercase"
                            sx={{
                                textWrap: 'wrap',
                            }}
                        >
                            {subtitle}
                        </MDTypography>
                    </MDBox>
                </MDBox>
            </MDBox>
            <Divider
                light={
                    (!darkMode && !whiteSidenav && !transparentSidenav) ||
                    (darkMode && !transparentSidenav && whiteSidenav)
                }
            />
            <List>
                {renderRoutes(preparedRoutes)}
            </List>
        </SidenavRoot>
    )
}

// Typechecking props for the Sidenav
Sidenav.propTypes = {
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
    brand: PropTypes.string,
    brandName: PropTypes.string.isRequired,
    // routes: PropTypes.arrayOf(PropTypes.object).isRequired,
}

export default Sidenav
