import { React } from 'react'
import MDBox from '../MDBox'
import CompanyCard from './CompanyCard'

export default function CompanyCards(props) {
    return (
        <MDBox display="flex" flexWrap="wrap" gap={2}>
            {props.companies.map((company, index) => {
                return <CompanyCard {...props} company={company} key={index} />
            })}
        </MDBox>
    )
}
