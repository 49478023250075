import { useContext } from 'react'
import MDBox from '../MDBox'
import MDTypography from '../MDTypography'
import { AppMessageContext } from '../../context/messaging/AppMessageContext'

export default function Flash({
    light,
    children,
}) {
    const appMessage = useContext(AppMessageContext)

    if (!appMessage) {
        return null
    }

    return (
        <MDBox pr={1}>
            <MDTypography
                component="span"
                variant="button"
                fontWeight="regular"
                color={light ? 'white' : 'dark'}
                opacity={light ? 0.8 : 0.5}
                sx={{
                    lineHeight: 0,
                    fontSize: '1.2rem',
                }}
            >
                {appMessage}
            </MDTypography>
        </MDBox>
    )
}
