/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.2.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2023 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

// prop-types is a library for typechecking of props.
import PropTypes from 'prop-types'

// @mui material components
import Collapse from '@mui/material/Collapse'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import Icon from '@mui/material/Icon'

// Material Dashboard 2 PRO React components
import MDBox from 'components/MDBox'

// Custom styles for the SidenavItem
import { item, itemContent, itemArrow } from 'examples/Sidenav/styles/sidenavItem'

// Material Dashboard 2 PRO React contexts
import { useMaterialUIController } from 'context/material-ui'

function SidenavItem({
    icon,
    color = 'info',
    name,
    active = false,
    nested = false,
    children = false,
    open = false,
    ...rest
}) {
    const [controller] = useMaterialUIController()
    const { miniSidenav, transparentSidenav, whiteSidenav, darkMode } = controller

    return (
        <>
            <ListItem
                {...rest}
                component="li"
                sx={(theme) =>
                    item(theme, { active, color, transparentSidenav, whiteSidenav, darkMode })}
            >
                <MDBox
                    sx={(theme) =>
                        itemContent(theme, {
                            active,
                            miniSidenav,
                            name,
                            open,
                            nested,
                            transparentSidenav,
                            whiteSidenav,
                            darkMode,
                        })
                    }
                >
                    <ListItemIcon
                        // sx={(theme) => collapseIconBox(theme, { transparentSidenav, whiteSidenav, darkMode })}
                    >
                        {typeof icon === 'string' ? (
                            <Icon
                                // sx={(theme) => collapseIcon(theme, { active })}
                            >{icon}</Icon>
                        ) : (
                            icon
                        )}
                    </ListItemIcon>
                    <ListItemText primary={name} />
                    {/* {children && (
            <Icon
              component="i"
              sx={(theme) =>
                itemArrow(theme, { open, miniSidenav, transparentSidenav, whiteSidenav, darkMode })
              }
            >
              expand_less
            </Icon>
          )} */}
                </MDBox>
            </ListItem>
            {children && (
                <Collapse in={open} timeout="auto" unmountOnExit {...rest}>
                    {children}
                </Collapse>
            )}
        </>
    )
}

// Typechecking props for the SidenavItem
SidenavItem.propTypes = {
    icon: PropTypes.node,
    color: PropTypes.oneOf(['primary', 'secondary', 'info', 'success', 'warning', 'error', 'dark']),
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
    active: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
    nested: PropTypes.bool,
    children: PropTypes.node,
    open: PropTypes.bool,
}

export default SidenavItem
