const detailsSection = 'details'

// number with at least one digit
const validNumberPattern = /^\d+$/

// number with at least one digit followed by up to two decimal digits
const validCurrencyPattern = /^\d+(\.\d{0,2})?$/

export const numericMeta = {
    section: detailsSection,
    forceLtr: true,
    forceRight: true,
}

export const firstFieldMeta = {
    autoFocus: true,
}

export const forgetValueMeta = {
    autoComplete: 'off',
}

export const firstMeta = {
    ...numericMeta,
    ...firstFieldMeta,
    ...forgetValueMeta,
}


function requiredFieldWithLabel(yup, label, meta) {
    return yup
        .label(label)
        .required(`נא להזין ${label}`)
        .meta(meta)
}

export function requiredCurrencyField(yup, label, meta) {
    return requiredFieldWithLabel(
        yup
            .string()
            .test(
                'is-valid-currency',
                `${label} לא תקין`,
                (val) => {
                    if (val != undefined) {
                        const isValid = validCurrencyPattern.test(val)
                        return isValid
                    }
                    return true
                },
            ),
        label,
        meta,
    )
}

export function requiredNumericStringField(yup, label, meta) {
    return requiredFieldWithLabel(
        yup
            .string()
            .matches(validNumberPattern, `${label} לא תקין`),
        label,
        meta,
    )
}
