import { React } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import schema from '../../schemas/company'
import MDButton from '../MDButton'
import SchemaTable from '../table/SchemaTable'

export default function CompaniesTable({ companies }) {
    const fieldIds = ['companyName', 'totalEntries', 'invalidEntries', 'companyId', 'companyType', 'reportingPeriodType', 'city', 'street']

    const navigate = useNavigate()

    function navigateToCompanyForm(companyId) {
        navigate(`/companies/${companyId}`)
    }

    function navigateToCompanyEntries(e, row) {
        const companyId = row.original.id
        navigate(`/companies/${companyId}/entries`)
    }

    const actionsColumn = {
        Header: 'פעולה',
        width: 300,
        accessor: 'action',
        enableSorting: false,
        Cell: (cell) => {
            const { id } = cell.row.original
            return (<>
                <MDButton
                    component={Link}
                    to={`/companies/${id}`}
                    variant="outlined"
                    color="primary"
                    sx={{
                        marginInlineEnd: 1,
                    }}
                    onClick={(e) => {
                        e.stopPropagation()
                        navigateToCompanyForm(id)
                    }}
                >
                    עריכה
                </MDButton>
            </>)
        },
    }

    const nonNullCompanies = companies || []
    const companiesWithStats = nonNullCompanies.map((company) => {
        const entries = company.entries || []
        const invalidEntries = entries.filter(entry => {
            return entry.status === 'Invalid'
        })
        return {
            ...company,
            totalEntries: entries.length,
            invalidEntries: invalidEntries.length,
        }
    })

    return (
        <SchemaTable
            schema={schema}
            data={companiesWithStats}
            fieldIds={fieldIds}
            columns={[actionsColumn]}
            onRowClick={(e, row) => navigateToCompanyEntries(e, row)}
            canSearch={false}
        />
    )
}
