import React, { useMemo, useState } from 'react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl'
import { CacheProvider } from '@emotion/react'
import createCache from '@emotion/cache'

import {
    useMaterialUIController,
    // setMiniSidenav,
    // setOpenConfigurator,
} from '../../context/material-ui'

// Material Dashboard 2 PRO React themes
import theme from '../../assets/theme'
import themeRTL from '../../assets/theme/theme-rtl'

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from '../../assets/theme-dark'
import themeDarkRTL from '../../assets/theme-dark/theme-rtl'

export default function Themer({ children }) {
    const [controller, dispatch] = useMaterialUIController()
    const {
        //   miniSidenav,
        direction,
        //   layout,
        //   openConfigurator,
        //   sidenavColor,
        //   transparentSidenav,
        //   whiteSidenav,
        darkMode,
    } = controller

    let selectedTheme
    if (darkMode) {
        selectedTheme = direction === 'rtl' ? themeDarkRTL : themeDark
    }
    else {
        selectedTheme = direction === 'rtl' ? themeRTL : theme
    }

    // Cache for the rtl
    const [rtlCache, setRtlCache] = useState(null)
    useMemo(() => {
        const cacheRtl = createCache({
            key: 'rtl',
            stylisPlugins: [rtlPlugin],
        })

        setRtlCache(cacheRtl)
    }, [])

    return (
        <CacheProvider value={rtlCache}>
            <ThemeProvider theme={selectedTheme}>
                <CssBaseline />
                {children}
            </ThemeProvider>
        </CacheProvider>
    )
}
