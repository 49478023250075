const RedirectToURL = (path, props) => {
    props.history.push(path)
}

const waitFor = (ms) => new Promise((res, rej) => setTimeout(res, ms))

const asyncForEach = async (array, callback) => {
    for (let index = 0; index < array.length; index++) {
        await callback(array[index], index, array)
    }
}

const DifferentiateJSONs = (obj1, obj2) => {
    const result = {}
    Object.keys(obj1).map((key) => {
        if (obj2[key] !== obj1[key]) {
            result[key] = obj2[key]
        }

        if (Array.isArray(obj2[key]) && Array.isArray(obj1[key])) {
            result[key] = DifferentiateJSONs(obj1[key], obj2[key])
        }

        if (typeof obj2[key] === 'object' && typeof obj1[key] === 'object') {
            result[key] = DifferentiateJSONs(obj1[key], obj2[key])
        }

        return null
    })
    return result
}

const JsonToArr = (jsonObj) => {
    let OriginObj = jsonObj
    let emptyArr = []

    for (let [key, value] of Object.entries(OriginObj)) {
        emptyArr.push([key, value])
    }

    return emptyArr
}

const fillIn = (text, maxLength, filler) => {
    text = `${text}`
    if (text.length < maxLength) {
        let fill = filler.repeat(maxLength - text.length)
        switch (filler) {
            case '0':
                return `${fill}${text}`
            case ' ':
                return `${text}${fill}`

            default:
                throw new Error('There isn\'t any case for \'' + filler + '\' in the fillIn() function\'s switch.')
        }
    }
    else {
        return text
    }
}

export {
    RedirectToURL,
    waitFor,
    asyncForEach,
    DifferentiateJSONs,
    JsonToArr,
    fillIn,
}
