const TOKEN_KEY = 'authenticationToken'
const USER_KEY = 'user'

export default class AuthStorage {
    getAuthenticationToken() {
        if (localStorage.getItem(TOKEN_KEY) !== null) {
            return localStorage.getItem(TOKEN_KEY)
        }
    }

    getAuthenticatedUser() {
        if (localStorage.getItem(USER_KEY) !== null) {
            const json = localStorage.getItem(USER_KEY)
            const user = JSON.parse(json)
            return user
        }
    }

    storeAuthentication(token, user) {
        localStorage.setItem(TOKEN_KEY, token)

        const json = JSON.stringify(user)
        localStorage.setItem(USER_KEY, json)
    }

    removeAuthentication() {
        localStorage.removeItem(TOKEN_KEY)
        localStorage.removeItem(USER_KEY)
    }

    isAuthenticated() {
        const token = this.getAuthenticationToken()
        return token != null
    }
}
