import React, { useEffect, useState } from 'react'
// import { FormattedMessage } from 'react-intl'
// import { getAuthenticationToken } from '../../helpers/auth.helper'
import Page from '../pages/Page'
import { Form, Formik } from 'formik'
// import FormField from 'components/forms/FormField'
import schema from '../../schemas/barcodeVoucherSchema'
import AppFormContainer from '../forms/AppFormContainer'
import AppFormSection from '../forms/AppFormSection'
import './BarcodeVoucherEditor.scss'

function BarcodeVoucherEditor({
    document,
    mode,
    documentCommands,
    readOnly,
    setDocumentDetailMutation,
}) {
    const [initialState, setInitialState] = useState({
        barcode: '',
        ...document,
    })
    const [isChanged, setIsChanged] = useState(false)

    const schemaDescription = schema.describe()
    const { fields } = schemaDescription

    const getSelectiveVoucherInitialState = async (voucherDetails) => {
        if (mode === 'new') {
            return
        }

        const requires = ['barcode']
        const result = voucherDetails
            .filter((field) => requires.includes(field[0]))
            .map((item) => {
                const obj = {}
                obj[item[0]] = String(item[1])
                return obj
            })
        return result
    }

    const setInitialStateFunc = async () => {
        if (mode === 'new') {
            return
        }

        const voucher = document[document.type]
        if (voucher == null) {
            return
        }

        const voucherDetails = Object.entries(voucher)
        const selectiveVoucherInitialState = await getSelectiveVoucherInitialState(voucherDetails)

        const initialStateObj = {}
        selectiveVoucherInitialState.forEach((property) => {
            const name = Object.keys(property)[0]
            initialStateObj[name] = property[name]
        })
        setInitialState(initialStateObj)
    }

    useEffect(() => {
        setInitialStateFunc()
    }, [mode])

    // const initializeEditMode = (resetEditMode) => {
    //   if (resetEditMode) {
    //     setIsChanged(false)
    //   }

    //   // Text Inputs
    //   const inputs = Array.from(document.querySelectorAll('#App > div.app_main > div.page-and-breadcrumbs > div > div > div > form input'))
    //   let isChangedElem = inputs.filter(input => input.name === 'barcode')
    //   if (isChangedElem.length > 0) {
    //     isChangedElem = isChangedElem[0]
    //     if (isChanged === false) {
    //       inputs.forEach((input) => {
    //         for (let i in initialState) {
    //           if (input.name === i) input.value = initialState[i]
    //         }
    //       })

    //       setIsChanged(true)
    //     }
    //   }
    // }

    const handleSubmit = (values) => {
        const body = {
            barcode: values.barcode,
        }

        const data = new FormData()
        const json = JSON.stringify(body)
        data.append('data', json)

        setDocumentDetailMutation.mutate({
            data,
            endpoint: 'Voucher',
        })
    }

    // const handleSubmit = async (e) => {
    //   e.preventDefault()

    //   let isSomethingEmpty = false

    //   let inputs = Array.from(e.target)
    //   inputs.pop() // last input is the submit button
    //   if (editMode) {
    //     inputs.pop()
    //   }
    //   for (let i = 0; i < inputs.length; i++) {
    //     const input = inputs[i]
    //     if (input.value === '') {
    //       isSomethingEmpty = true
    //     }
    //     else {
    //       let obj = finalState
    //       if (input.type !== 'file') {
    //         obj[input.name] = input.value
    //       }

    //       setFinalState(obj)
    //     }
    //   }

    //   isSomethingEmpty = editMode ? false : isSomethingEmpty

    //   if (isSomethingEmpty === true) {
    //     platform.showMessageBoxSync({ type: 'warning', buttons: [], title: 'טופס לא תקין', message: 'אחד או יותר מהשדות ריקים, יש לספק את כל המידע הנדרש בעמוד' })
    //   }
    //   else {

    //     let DiffJsons = null

    //     if (editMode === true) {
    //       DiffJsons = DifferentiateJSONs(initialState, finalState)

    //       setDifferentiation(DiffJsons)
    //     }

    //     if (DiffJsons === null || Object.keys(DiffJsons).length > 0) {
    //       createVoucherObject(e, DiffJsons)
    //         .then(() => {
    //           RedirectToURL(`/companies/${companyId}/entries/${entryId}`, props)
    //         })
    //         .catch(() => {
    //           platform.showMessageBoxSync({ type: 'error', buttons: [], title: 'טופס לא תקין', message: 'ישנה בעיה ביצירת השובר, פנה למנהל מערכת' })
    //         })
    //     }
    //     else {
    //       platform.showMessageBoxSync({ type: 'warning', buttons: [], title: 'טופס לא תקין', message: 'אין שום שינוי באובייקט הקיים לבין הנתונים האלו' })
    //     }
    //   }
    // }

    // const title = editMode ? 'עריכת שובר ברקוד' : 'העלאת שובר ברקוד'
    const commands = [
        ...documentCommands,
    ]

    const page = (
        <Page
            title={`שובר ברקוד ${document.id}`}
            status={document.status}
            commands={commands}
        >
            <Formik
                onSubmit={(values, action) => {
                    handleSubmit(values)
                }}
                enableReinitialize={true}
                validationSchema={schema}
                initialValues={initialState}
            >
                {() => (
                    <Form id="form">
                        <AppFormContainer>
                            <AppFormSection
                                title="פרטי השובר"
                                section="details"
                                fields={fields}
                                disabled={readOnly}
                                xs={12}
                                md={6}
                                lg={6}
                            />
                        </AppFormContainer>
                    </Form>
                )}
            </Formik>
        </Page>
    )

    return page
}

export default BarcodeVoucherEditor
