import axios from 'axios'
import qs from 'qs'
import AuthStorage from '../units/auth/AuthStorage'

function defaultResponseHandler(response) {
    return response?.data?.data
}

export async function fetch(options) {
    const {
        method = 'get',
        path,
        baseUrl = process.env.REACT_APP_STRAPIURL,
        params,
        headers,
        data,
        responseHandler,
        rethrow = true,
    } = options

    const url = `${baseUrl || process.env.REACT_APP_STRAPIURL}${path || ''}`

    const fullConfig = {
        method,
        url,
        params,

        headers: {
            'X-Client-Version': 'baseline',
            ...headers,
        },
        data,
    }

    if (url.startsWith(process.env.REACT_APP_STRAPIURL)) {
        fullConfig.paramsSerializer = (params) => qs.stringify(params)
    }

    let result
    try {
        const response = await axios(fullConfig)
        const handler = responseHandler || defaultResponseHandler
        result = handler(response)
    }
    catch (error) {
        console.log(`error`, error)

        if (rethrow) {
            throw error
        }
    }

    return result
}

export async function authenticatedFetch(options) {
    const authStorage = new AuthStorage()
    const token = authStorage.getAuthenticationToken()
    if (token != null) {
        const { headers, ...rest } = options
        const authenticatedOptions = {
            ...rest,
            headers: {
                ...headers,
                'Authorization': `Bearer ${token}`,
            },
        }
        return fetch(authenticatedOptions)
    }
    else {
        throw new Error('Not authenticated. Cannot fetch.')
    }
}

export async function fetchDocumentForBreadcrumb(id) {
    const params =
        {
            params: {
                'fields[0]': 'id',
                'fields[1]': 'type',
                'fields[2]': 'schedule',
                'populate[entry][fields][0]': 'id',
                'populate[entry][fields][1]': 'status',
                'populate[check][fields][0]': 'id',
                'populate[check][fields][1]': 'number',
                'populate[check][fields][2]': 'bank',
                'populate[check][fields][3]': 'branch',
                'populate[check][fields][4]': 'account',
                'populate[check][fields][5]': 'currency',
                'populate[images][fields][0]': 'id',
                'populate[images][fields][1]': 'type',
                'populate[images][fields][2]': 'url',
                'populate[images][populate][file][fields][0]': 'id',
            },
        }
    return authenticatedFetch({
        path: `/documents/${id}`,
        ...params,
    })
}

export async function fetchMinimalCompanyInfo(id) {
    const params = {
        fields: [
            'id',
            'companyName',
        ],
    }

    return authenticatedFetch({
        path: `/companies/${id}`,
        ...params,
    })
}
